import React from "react";
import SelPria from "../Components/SelPria";
import pria1 from "../assets/PRIA1.webp";
import autosim from "../assets/autosim1_PNG.webp";
import Insurance from "../assets/Insurance.webp";
import FeaturesPria from "../Components/FeaturesPria";
import PriaForm from "../Components/PriaForm";

const Pria = () => {
  const data = [
    {
      key: 0,
      title: "EDGAR",
      img: pria1,
      subtitle: "Add products to PRIA",
      desc: "PRIA helps product suppliers, resellers, and vendors to include their products for digital assessment. It allows for quantification and qualification of solutions to a higher degree of granularity and AI-enabled matching. PRIA customers get included as qualified products for the “auto” generated and interactive advisory (auto-simulation and manual-simulation) for improving the building performance in short, medium, and long term. ",
      color: "red",
      btnName: "Video Tutorial",
      link: "",
    },
    {
      key: 1,
      title: "PRIA",
      subtitle: "Products get ready for specific impact assessment",
      img: autosim,
      desc: "In a first-of-its-kind on-cloud performance simulation using building physics and machine-learning, BtrLyf estimates building performance and guides users on how it can be improved through sustainable solutions ranging from building envelope, lighting to air-conditioning, controls and renewable energy. Once a specific solution or product is added to the BtrLyf AI Green Assessor, via PRIA, its ready for analysis on thousands of buildings in the city. The analysis includes energy saving potential, costs and payback calculations of the product specific to the chosen building.",
      color: "red",
      btnName: "Video Tutorial",
      link: "",
    },
    {
      key: 2,
      title: "EDGAR",
      img: Insurance,
      subtitle: "Get the power of the network to present solutions",
      desc: "The best part is that the solution provider or reseller need not do the analysis themselves all the time. It will be done by any consultant, engineer, building owner/manager, student, professor, regulator or even financier who has some interest in green buildings in the city. This is a powerful business proposition for solution providers and resellers to get an edge over the competition in a rapidly digitalizing world. It not only saves time and effort on qualification and quantification of their products to prospective buyers, but it also helps build the brand. ",
      color: "red",
      btnName: "Video Tutorial",
      link: "",
    },
  ];
  return (
    <>
      <SelPria />
      <PriaForm />
      <FeaturesPria data={data} />
    </>
  );
};

export default Pria;
