import React from "react";
import contactImage from "../../assets/contactus.png";
import "./style.css";
import HubspotForm from "react-hubspot-form";
// import config from "../config";

export default function NewContact() {
  return (
    <>
      <div className="contact-main">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h3 className="Contact-heading">Contact Us</h3>
            </div>
            <div className="col-md-6"></div>
            <div className="col-md-6 d-flex justify-content-center">
              {/* <div className="contact-form">
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input type='text' name="name" classname="form-control" placeholder="Enter Name"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type='email' name="email" classname="form-control" placeholder="Email Address"/>
                    </div>
                    <br/>
                    <div className="form-group">
                        <textarea className="form-control" rows={6} placeholder="Enter your message"></textarea>
                    </div>
                    <br/>
                    <button type="submit" className="btn btn-primary form-control">Submit</button> 
                 </div> */}
              <HubspotForm
                portalId="6028177"
                formId="a160d94e-741e-4773-8fd1-3d3197c55e0e"
                onSubmit={() => console.log("Submit!")}
                onReady={(form) => console.log("Form ready!")}
                loading={<div>Loading...</div>}
              />
            </div>
            <div className="col-md-6">
              <div className="contact-image">
                <img src={contactImage} width="80%" alt="contact us" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
