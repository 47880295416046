import { useEffect, useState } from "react";
// import { BrowserRouter} from "react-router-dom";
import { BrowserRouter, useHistory } from "react-router-dom";
import "./App.css";
import { Header } from "./Components/Header";
import Home from "./Pages/Home";
import NotFound from "./Components/NotFound";
import NavBar from "./Components/NavBar";
import Edgar from "./Pages/Edgar";
import Pria from "./Pages/Pria";
import WhatsNew from "./Pages/WhatsNew";

import Faqq from "./Components/Faq";
import AboutUs from "./Components/AboutUs";
import Facs from "./Components/Facs";
import Topbar from "./Components/Topbar/Topbar";
import config from "./config";
import NewScreen from "./Pages/NewUi/NewScreen";
import NewPricing from "./Pages/NewPrincing/NewPricing";
import NewAboutUs from "./Pages/NewAboutUs/NewAboutUs";
import NewPria from "./Pages/NewPria/NewPria";
import NewEdgar from "./Pages/NewEdgar/NewEdgar";
import NewGrace from "./Pages/NewGrace/NewGrace";
import Form from "react-bootstrap/Form";
import NewNavbar from "./Components/NewNavbar/NewNavbar";
import NewWhy from "./Pages/NewWhy/NewWhy";
import NewFAQ from "./Pages/NewFAQ/NewFAQ";
import NewHome from "./Pages/NewHome/NewHome";
import NewFooter from "./Components/NewFooter/NewFooter";
import Routes from "./Routes";
import Switch from '@mui/material/Switch';

function App() {
  // useEffect(() => {
  //   window.location.href = config.wixsite_URL;
  // }, []);
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const history = useHistory();
  const [UiState, SetUiState] = useState(window.location.pathname.includes('new')?0:1);
  // useEffect(()=>
  // {
  //   if(window.location.pathname.includes('new'))
  //   {
  //     SetUiState(1)
  //   }
  // },[])
  const handleSwitch = () => {
    UiState === 1 ? SetUiState(0) : SetUiState(1);
  };
  return (
    <BrowserRouter>
      {/* <Topbar /> */}
      {/* <Form>
        <Form.Check
          type="switch"
          id="custom-switch"
          label="Beta Mode"
          defaultChecked={window.location.pathname.includes('new')?true:false}
          className="ui-toggle-switch"
          label-position="left"
          onChange={() => handleSwitch()}
        />
      </Form> */}
      <div className="ui-toggle-switch">
      <p>Beta Mode</p><Switch checked={UiState===1?false:true}  {...label} onChange={() => handleSwitch()} />
      </div>
      {UiState === 1 ? (
        <>
          <Header />
          <NavBar />
        </>
      ) : (
        <NewNavbar />
      )}

      <Routes  Uivalue={UiState}/>
      {UiState === 0 ? <NewFooter /> : null}
    </BrowserRouter>
  );
}

export default App;
