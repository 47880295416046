import React, { useEffect, useState } from "react";
import Switch from "@material-ui/core/Switch";
// import { useHistory } from "react-router-dom";
import styled from "styled-components";
import logo from "../../assets/newlogo.png";
import { Button } from "@material-ui/core";
// import defaultAvatar from "../../../assets/Images/profile.jpg";
import "./Topbar.css";
// import Path from "Routes/Path";
// import { signOut } from "../../../Redux/Actions/Auth";
// import { useSelector } from "react-redux";
import {
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownToggle
} from "reactstrap";
// import { dispatch } from "rxjs/internal/observable/range";
// import { useDispatch } from "react-redux";
// import PrimaryButton from "../Buttons/PrimaryButton";
// import Config from "../../../Utils/Config";

const CustomLogoImage = styled.img`
  height: 50px;
  width: 50px;
`;
const CustomNavBand = styled.a`
  @media screen and (min-width: 1024px) {
    margin-right: 90px !important;
    // padding: 0px 0px 0px 115px;
  }
  margin-right: 90px !important;

  @media screen and (max-width: 321px) {
    margin-right: 0px !important;
  }
`;

function Topbar() {
  // const auth = useSelector((state: any) => state.Auth);
  // console.log("wdqwdeafwkajdsfnkajsdfnalsjidfnsiusdaaiualuifanleifu" , auth)
  // const history = useHistory();
  const [toggle, setToggle] = useState(0);
  const [switchText, setSwitchText] = useState("Classic UI Off");
  const [profileImageError, setProfileImageError] = useState(false);
  // const dispatch = useDispatch();
  const [welcomeMessage, setWelcomeMessage] = useState("Welcome to SPA!");

  // const label = { inputProps: { 'aria-label': 'Switch demo' } };
  // const handleToggle = () => {
  //   toggle === 0 ? handleroute(1) : handleroute(0);
  // };

  // const handleroute = value => {
  //   setToggle(value);
  //   if (value === 1) {
  //     history.push(Path.NewUi);
  //     setSwitchText("Classic UI On");

  //     // history.push(Path.NewUi)
  //   } else {
  //     history.push(Path.Map);
  //     setSwitchText("Classic UI Off");
  //   }
  // };
  console.log("toggle value===", toggle);

  //Welcome message function

  useEffect(() => {
    setWelcomeMessage("Welcome to SPA!");
    setTimeout(() => setWelcomeMessage(""), 5000);
  }, []);

  //Login logout profile functions
  // const profileClick = () => {
  //   history.push(Path.Profile);
  // };

  // const logOutUser = () => {
  //   caches.keys().then(names => {
  //     names.forEach(name => {
  //       caches.delete(name);
  //     });
  //   });
  //   // dispatch({ type: CLEAR_AUTH_TO_LOCAL });
  //   // this.dispatch(signOut());
  //   dispatch(signOut());
  //   history.push(Path.LogIn);
  //   //window.open("https://selbtrlyf.auth.ap-southeast-1.amazoncognito.com/login?client_id=13kmr001lvr1qa42urtlvuohep&response_type=code&scope=openid&redirect_uri=https://d2lrk1b5rk52hb.cloudfront.net")
  // };

  // const loginFragment = () => {
  //   return (
  //     <UncontrolledDropdown
  //       nav
  //       inNavbar
  //       className="topbar_profile_dropdown_section"
  //     >
  //       <DropdownToggle nav caret>
  //         <img
  //           alt="avatar"
  //           src={
  //             auth.user_image === "" || profileImageError
  //               ? defaultAvatar
  //               : auth.user_image
  //           }
  //           //{defaultAvatar}
  //           onError={() => {
  //             setProfileImageError(true);
  //             return true;
  //           }}
  //           className="user_profile_avatar"
  //         />
  //         &nbsp;
  //         <span className={"nav-username-label"}>
  //           <b>{auth.idToken.payload.name}</b>&nbsp;
  //           <i className="icon-menu"></i>
  //         </span>
  //       </DropdownToggle>
  //       <DropdownMenu right className="dropdown-menu">
  //         <DropdownItem
  //           onClick={() => profileClick()}
  //           className="dropdown-item"
  //         >
  //           <b>Profile</b>
  //         </DropdownItem>
  //         {/* <DropdownItem divider /> */}
  //         <UncontrolledDropdown nav inNavbar>
  //           <DropdownToggle nav caret style={{ color: "black" }}>
  //             <b>Switch Apps</b>
  //           </DropdownToggle>
  //           <DropdownMenu right>
  //             {/* <DropdownItem onClick={() => history.push(Path.Home)}>
  //               <b>EDGAR</b>
  //             </DropdownItem>
  //             <DropdownItem divider />
  //             <DropdownItem onClick={() => window.open(Config.react_app_grace)}>
  //               <b>GRACE</b>
  //             </DropdownItem>
  //             <DropdownItem divider />
  //             <DropdownItem onClick={() => window.open(Config.react_app_SPA)}>
  //               <b>SPA</b>
  //             </DropdownItem>
  //             <DropdownItem divider />
  //             <DropdownItem onClick={() => window.open(Config.react_app_admin_panel)}>
  //               <b>ADMIN PANEL</b>
  //             </DropdownItem>
  //             <DropdownItem divider />
  //             <DropdownItem onClick={() => window.open(Config.react_app_lisa)}>
  //               <b>LISA</b>
  //             </DropdownItem> */}
  //             <DropdownItem onClick={() => history.push(Path.Home)}>
  //               <b>EDGAR</b>
  //             </DropdownItem>
  //             <DropdownItem divider />
  //             <DropdownItem>
  //               <a
  //                 style={{ textDecoration: "none", color: "black" }}
  //                 href={Config.react_app_grace}
  //               >
  //                 <b>GRACE</b>
  //               </a>
  //             </DropdownItem>
  //             <DropdownItem divider />
  //             <DropdownItem>
  //               <a
  //                 style={{ textDecoration: "none", color: "black" }}
  //                 href={Config.react_app_SPA}
  //               >
  //                 <b>SPA</b>
  //               </a>
  //             </DropdownItem>
  //             <DropdownItem divider />
  //             <DropdownItem>
  //               <a
  //                 style={{ textDecoration: "none", color: "black" }}
  //                 href={Config.react_app_admin_panel}
  //               >
  //                 <b>ADMIN PANEL</b>
  //               </a>
  //             </DropdownItem>
  //             <DropdownItem divider />
  //             <DropdownItem>
  //               <a
  //                 style={{ textDecoration: "none", color: "black" }}
  //                 href={Config.react_app_lisa}
  //               >
  //                 <b>LISA</b>
  //               </a>
  //             </DropdownItem>
  //           </DropdownMenu>
  //         </UncontrolledDropdown>
  //         <DropdownItem onClick={() => logOutUser()}>
  //           <b>Logout</b>
  //         </DropdownItem>
  //       </DropdownMenu>
  //     </UncontrolledDropdown>
  //   );
  // };
  return (
    <>
      <div className="sticky_topbar_main">
        <div className="sticky-topbar">
          {/* <h5>SEL BTRLYF</h5> */}
          <CustomNavBand className="navbar-brand" href="/">
            <CustomLogoImage src={logo} alt="Logo" />
          </CustomNavBand>
          <h3>{welcomeMessage}</h3>
          <div className="new_ui_switch">
            <div className="new_ui_switch1">
              <p>
                <b>{switchText}</b>
              </p>
              <Switch
                className="switch_button"
                color="primary"
                // onChange={handleToggle}
              />
            </div>
            <div>
              {/* {auth ? (
                <ul className="navbar-nav mr-0 align-items-center">
                  {loginFragment()}
                </ul>
              ) : ( */}
                <Button
                variant="contained"
                color="primary"
                  // type="button"
                  // fontSize={18}
                  // height={"50px"}
                  // width={"90px"}
                  // className="btn-primary bigButton loginButton"
                  // borderRadius="5px"
                  // clickHandler={() => {
                  //   history.push(Path.LogIn);

                  //   // window.open("https://selbtrlyf.auth.ap-southeast-1.amazoncognito.com/login?client_id=13kmr001lvr1qa42urtlvuohep&response_type=code&scope=openid&redirect_uri=https://edgar.selbtrlyf.com/map")
                  // }}
                >
                  Login
                </Button>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Topbar;
