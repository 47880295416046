import React from "react";
import logo from "../../assets/newlogo2.png";
import "./style.css";
import {
  AiOutlineMail,
  AiFillFacebook,
  AiFillTwitterSquare,
  AiFillYoutube,
} from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { Link } from "react-router-dom";

export default function NewFooter() {
  return (
    <>
      <div className="footer-main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2 col-sm-6">
              <img src={logo} alt="logo" height="70"></img>&nbsp;&nbsp;
            </div>
            <div className="col-md-3 col-sm-6">
              <p className="footer-head">Contact Us</p>
              <div className="footer-contact">
                <AiOutlineMail
                  style={{ marginRight: "20px" }}
                  color="#0088d6"
                />
                <p className="footer-text">admyn@btrlyf.com</p>
              </div>
              <div className="footer-contact">
                <GoLocation style={{ marginRight: "20px" }} color="#0088d6" />
                <p className="footer-text">
                  20 Cecil Street, #05-03 PLUS, Singapore 049705
                </p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <p className="footer-head">Our Services</p>
              <div className="row">
                <div className="col-sm-6">
                  <Link to="new-edgar">
                    <p className="footer-text">EDGAR</p>
                  </Link>
                </div>
                <div className="col-sm-6">
                  <Link to="new-grace">
                    <p className="footer-text">GRACE</p>
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <Link to="new-lisa">
                    <p className="footer-text">LISA</p>
                  </Link>
                </div>
                <div className="col-sm-6">
                  <Link to="new-pria">
                    <p className="footer-text">PRIA</p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <p className="footer-head">Follow Us</p>
              <div className="row">
                <div className="col-sm-4">
                  <a href="https://www.facebook.com/thebtrlyf">
                    <AiFillFacebook
                      style={{ fontSize: "40px" }}
                      color="#0088d6"
                    />
                  </a>
                </div>
                <div className="col-sm-4">
                  <a href="https://twitter.com/btrlyf_built">
                    <AiFillTwitterSquare
                      style={{ fontSize: "40px" }}
                      color="#0088d6"
                    />
                  </a>
                </div>
                <div className="col-sm-4">
                  <a href="https://www.youtube.com/channel/UCWpPA_dB05f6uvDbkq3BauQ">
                    <AiFillYoutube
                      style={{ fontSize: "40px" }}
                      color="#0088d6"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-1 col-sm-6">
              <IoIosArrowDropupCircle
                style={{ fontSize: "60px" }}
                color="#0088d6"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
