import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import PRIA from "../assets/PRIA.webp";

const SelPria = () => {
  return (
    <div>
      <br />
      <h2 className="text-primary text-center"> SelBtrLyf-PRIA</h2>
      <Row className="  d-flex">
        <Col>
          <img
            src={PRIA}
            className="rounded mx-auto d-block"
            style={{ height: 250 }}
            alt="hi"
          ></img>
        </Col>
        <Col style={{ marginRight: 30 }}>
          <Container>
            <p className="text-center">
              PRIA (Product Inclusion in Assessment) helps product suppliers,
              resellers and vendors to include their products for digital
              assessment in our EDGAR tool. Currently, the best technical sales
              tool used by most solution providers and resellers is ‘case
              studies’ of other projects that have benefited from their
              solution. The quantification of benefits for the proposed new
              project are mostly done only after the project is secured or when
              an initial agreement is reached with the potential buyer. This
              quantification is done with the help of qualified internal
              technical staff or third-party experts, which increases the cost
              and time of the sales cycle.
            </p>
            <p>
              {" "}
              ​ PRIA helps your product to be featured in the auto and manual
              simulation of building performance improvement projects on the
              platform for AI-matched buildings. It also auto calculates the
              benefits and financials of applying the specific product as a
              Energy Conservation Measure (ECM) in the green building of choice.
              See full list of features below
            </p>
          </Container>
        </Col>
      </Row>
      {/* <button
        style={{
          backgroundColor: "red",
          width: "300px",
          marginRight: "100px",
        }}
        className="rounded-pill myButton"
      >
        Start with Edgar for free
      </button> */}
      <br />
    </div>
  );
};

export default SelPria;
