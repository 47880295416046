import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import InboxIcon from "@material-ui/icons/MoveToInbox";
// import DraftsIcon from "@material-ui/icons/Drafts";
// import SendIcon from "@material-ui/icons/Send";
import { Link } from "react-router-dom";
import config from "../../config";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#edf9fd",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function MenuDropDown() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        // color="transparent"
        onClick={handleClick}
        style={{ background: "#edf9fd", border: "none", outline: "none" }}
      >
        <span style={{ color: "#0088d6" }}>Solutions</span>
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem>
          <Link to="/new-edgar">
            <p className="btrlyf-apps">EDGAR (Asses)</p>
          </Link>
        </StyledMenuItem>
        <StyledMenuItem>
          <p
            className="btrlyf-apps"
            onClick={() => window.open(config.btrlyf_URL)}
          >
            LISA (Market Listing)
          </p>
        </StyledMenuItem>
        <StyledMenuItem>
          <Link to="/new-pria">
            <p className="btrlyf-apps">PRIA (Qualify)</p>
          </Link>
        </StyledMenuItem>
        <StyledMenuItem>
          <Link smooth to="/new-grace">
            <p className="btrlyf-apps">GRACE (Certify)</p>
          </Link>
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
