import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const PriaForm = () => {
  return (
    <>
      <Row id="WhyBtrlyf">
        <Col>
          <h2 className="text-white text-center bg-dark">
            Get your products or solutions on SelBtrLyf-PRIA
          </h2>
        </Col>
      </Row>
      <Container>
        <form id="contact-form" className="form-inline">
          <br />
          <fieldset>
            <legend
              style={{
                fontSize: "20px",

                color: "black",
              }}
            >
              Please fill up the form below and we will get in touch with you
              soonest!
            </legend>
            <br />
            <br />
            <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="name">First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    required
                    style={{ background: "cfd7ff" }}
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label htmlFor="name">Last Name</label>
                  <input
                    // className="myForm"
                    style={{ background: "cfd7ff" }}
                    placeholder="Last Name"
                    type="text"
                    className="form-control"
                    aria-describedby="emailHelp"
                    required
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="name">Phone Number</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone Number"
                    required
                    style={{ background: "cfd7ff" }}
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Email address</label>
                  <input
                    // className="myForm"
                    style={{ background: "cfd7ff" }}
                    placeholder="Email"
                    type="email"
                    className="form-control"
                    aria-describedby="emailHelp"
                    required
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="name">City</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="City"
                    required
                    style={{ background: "cfd7ff" }}
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label htmlFor="name">Country</label>
                  <input
                    // className="myForm"
                    style={{ background: "cfd7ff" }}
                    placeholder="Email"
                    type="text"
                    className="form-control"
                    aria-describedby="emailHelp"
                    required
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="name">Company Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Company Name"
                    required
                    style={{ background: "cfd7ff" }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Comments (if any)</label>
                  <input
                    // className="myForm"
                    style={{ background: "cfd7ff" }}
                    placeholder="Comments "
                    type="text"
                    className="form-control"
                    aria-describedby="emailHelp"
                    required
                  />
                </div>
              </Col>
            </Row>
            <br />

            <br />
            <button
              type="submit"
              className="btn btn-primary rounded-pill"
              style={{
                width: "120px",
                marginLeft: "500px",
                marginRight: "550px",
              }}
            >
              Submit
            </button>
          </fieldset>
        </form>
        <br />
        <br />
      </Container>
    </>
  );
};

export default PriaForm;
