import React from "react";
import { Row, Col } from "react-bootstrap";

const FeaturesPria = (props) => {
  const data = props.data.map((data1) => {
    return (
      <div className="bg-white">
        <br />
        <Row>
          <br />
          <Col>
            <img
              src={data1.img}
              className="rounded mx-auto d-block "
              style={{ height: 250 }}
              alt="hi"
            ></img>
          </Col>
          <Col>
            <h4>{data1.subtitle}</h4>
            <br />
            <p>{data1.desc}</p>
          </Col>
        </Row>
        <br />
      </div>
    );
  });
  return (
    <div>
      <Row>
        <Col>
          <h2 className="text-white text-center bg-dark">
            Features of SelBtrLyf-PRIA
          </h2>
        </Col>
      </Row>
      <br />
      <Row>
        <br />
        {data}
        <br />
      </Row>
      <br />
    </div>
  );
};

export default FeaturesPria;
