import React from 'react';

function NotFound() {
  return (
    <div>
      <h1> Page Does Not exist</h1>
    </div>
  );
}

export default NotFound;
