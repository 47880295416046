import React from "react";
import "./style.css";
import aboutus from "../../assets/aboutus.png";
import { MdOutlinePictureAsPdf } from "react-icons/md";
import partners from "../../assets/partners.png";
import partner2 from "../../assets/partner2.png";
import NewNewsletter from "../../Components/NewNewsletter/NewNewsletter";
import softtech from "../../assets/softtechname.png";

export default function NewAboutUs() {
  return (
    <>
      <section id="banner">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h3 className="about-us-head">About Us</h3>
            </div>
            <div className="col-md-6"></div>
            <div className="col-md-6">
              <p className="about-para">
                {" "}
                Qi Square Pte Ltd is a Singapore based company, setup in June
                2017, and focusing on the digitalization of sustainable built
                environment. As a spin-off company of the prestigious Nanyang
                Technological University (NTU), Singapore, it has a strong
                technological background and its founders have more than two
                decades of experience each in the domains of green buildings,
                energy modelling and simulation, performance assessment/auditing
                and project financing.
              </p>
              <p className="about-para">
                The company has received technology development grants and
                support from several Singapore government agencies such as the
                Enterprise Singapore, Building & Construction Authority (BCA),
                Energy Market Authority (EMA) and JTC Corporation. The company
                has been able to attract large corporate clients such as DBS
                Bank, Singtel and Mercatus in Singapore as well as international
                clients and partners such as a Witteveen Bos (the Netherlands)
                and the Department of Energy (DOE) of Philippines.
              </p>
              <p className="about-para">
                The company has been recognized as an emerging start-up leader
                and innovator in the region and has been featured in the Energy
                CIO Insight’s ‘Top-10 Energy Tech Providers APAC 2019’ listing,
                ‘APAC 25 Global Cleantech 2021’ listing by Cleantech Group. It
                has recently also has been added to the list of KPMG’s latest
                ‘Real Estate Innovations Overview 2021’.
              </p>
              <p className="about-para">
                The company recently (on 1st September 2021) officially launched
                its ‘BtrLyf- Digital Built Environment’ Ecosystem platform in
                the backdrop of the need for urgent global climate action and
                the Singapore Green Plan 2030 (announced in Feb 2021), which
                calls for buildings in Singapore to achieve aggressive
                performance improvement targets and green certification by 2030.
                BtrLyf also helps significantly in improving productivity and
                reducing costs of achieving the UN Sustainable Development Goals
                (SDGs)
              </p>
            </div>
            <div className="col-md-6">
              <div className="about-image-block">
                <img src={aboutus} className="img-fluid" alt="about us" />
                <br />
                <a href="https://sbl-resources.s3.ap-southeast-1.amazonaws.com/PDF/BTRLYF+for+Asset+Managers_V2.pdf?index=true">
                  <button className="btn btn-outline-primary">
                    <MdOutlinePictureAsPdf /> Know More
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="strategic">
        <div className="stategic-partner">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h3 className="strategic-head">Strategic Partner</h3>
              </div>
              <div className="col-md-6"></div>
              <div className="col-md-4 col-sm-6">
                <img
                  src={softtech}
                  className="img-fluid"
                  alt="Softtech engr branding"
                />
              </div>
              <div className="col-md-8 col-sm-6">
                <p>
                  {" "}
                  <b>SoftTech Engineers Limited </b> (NSEI: SOFTTECH) is a
                  leading IT company facilitating business and technology
                  transformation across the AEC industry through innovative
                  software products and solutions. Equipped with 25 years of
                  deep domain expertise and industry knowledge, SoftTech has
                  helped more than 4500 clients in India and around the world to
                  gain a competitive edge and lead from the front in the
                  industry.
                </p>
                <p>
                  In September 2021, SoftTech announced their strategic
                  investment in Qi Square Pte Ltd. This strategic and technology
                  alliance marks the combined synergies of the two companies in
                  extending the BtrLyf platform. See more details here.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="partners">
        <div className="contaiener-fluid py-5">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-6">
              <h3 className="about-us-head">Our Customers & Partners</h3>
            </div>
          </div>
          <div className="container">
            <div className="d-flex w-100 justify-content-center">
              <img src={partners} alt="Our Customers & Partners" width="80%" />
            </div>

            <div className="d-flex w-100 justify-content-center">
              <div className="recorganised-box">
                <p>Proud to be recognized as:</p>
                <img
                  src={partner2}
                  width="65%"
                  alt="Proud to be recognized as"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <NewNewsletter />
    </>
  );
}
