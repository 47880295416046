import React from "react";
import { Col, Row } from "react-bootstrap";
import Faq from "react-faq-component";
import faq from "../assets/faq.jpg";
import faq1 from "../assets/faq1.jpg";
import Faq_image from "../assets/Faq_image.PNG";
import Contact from "../Components/Contact";
import Facs from "./Facs";

const data = {
  rows: [
    {
      title: "How to pronounce 'BtrLyf'? What does it stand for?",
      content: `'BtrLyf' is a pronounced as 'Better Life'. BtrLyf is a registered
      trademark of Qi Square Pte Ltd. We named our platform 'BtrLyf' as it
      aims to create a better life for all of us by creating a btr lyf for
      people in-charge of making btr buildings. We do that through
      digitalization and collaborative artificial intelligence. Btr
      buildings save energy and costs, reduce carbon emissions, and create a
      sustainable built environment lyf for everyone`,
    },
    {
      title: "What problem does it solve?",
      content: (
        <div>
          <p>
            Buildings consume 30% of energy and contribute to 40% of carbon
            emissions globally. By 2050, the world’s building stock will double,
            increasing the impact on climate significantly. Its demand on
            natural resources accelerates climate change, and inefficient,
            unhealthy buildings are affecting our livelihoods.
          </p>
          <p>
            Buildings can save 20% to 60% in operating costs through proven
            green retrofit measures with an excellent return on investmnet.
            However, building industry players do not have the tools to easily
            identify and quantify energy saving opportunities, evaluate the
            latest technological solutions and access financing options. They
            end up spending a lot of time in administration of green projects
            and finding the right partners to work with
          </p>
          <p>
            The lack of effective data accessibility and exchange inhibits the
            growth of the industry. This in turn severely prohibits timely
            deployment of successful decarbonization strategies that are
            urgently required to mitigate and limit climate change and global
            warming impacts.
          </p>
        </div>
      ),
    },
    {
      title: "What exactly does BtrLyf do?",
      content: (
        <div>
          <p>
            BtrLyf gets the industry (building owners/managers, consultants,
            tech providers, ESCOs, financiers, etc.) to work together on green
            building projects with better data exchange for enhanced
            productivity and continuous improvement. It is an unified digital
            platform that helps co-creation of sustainable buildings by enabling
            collaboration and AI-enabled assessment of green buildings and
            related solutions.
          </p>
          <p>
            By increasing the productivity of green projects through
            digitalization and collaboration, BtrLyf enables the acceleration of
            super low energy and zero energy buildings and contributes
            positively to the decarbonization of the built environment. The
            resultant impact for the industry includes reduced transaction time,
            lower marketing costs, faster sales cycles, lower investment risk
            and improved productivity of business processes by up to 10x. In
            short, BtrLyf is a green building market platform that helps
            accelerate sustainable development through digitalization.
          </p>
        </div>
      ),
    },
    {
      title: "What are the benefits of using BtrLyf?",
      content: (
        <div>
          <p>
            Through digitalization, BtrLyf eliminates a lot of issues associated
            with manual work and disconnected workflows in green building
            projects. The benefits of using BtrLyf are varied and it helps
            different industry partners in different ways. Below are the just
            some of the key benefits achived for the industry:
          </p>
          <ul>
            <li>
              {" "}
              Eliminates the need for expensive middlepersons for effective deal
              execution
            </li>
            <li>
              {" "}
              Allows for Do-it-Yourself technical analysis without the need for
              technical skills
            </li>
            <li>
              {" "}
              Prevents duplication of efforts that are common in the industry
              due to lack of relevant data exchange and archiving
            </li>
            <li>
              {" "}
              Avoids any travel or reduces physical touchpoints by providing
              secure remote digital access to green building data
            </li>
            <li>
              {" "}
              Provides low-cost and instant assessment and solution fit analysis
            </li>
            <li>
              {" "}
              Offers continuous performance upgrade and business development
              opportunities
            </li>
            <li>
              {" "}
              Provides a technically sound, validated, and neutral green
              assessment
            </li>
            <li>
              {" "}
              Takes advantage of open-source data and connected data from 3rd
              party APIs
            </li>
            <li>
              {" "}
              Integrates with different data sources and conducts a
              comprehensive assessment
            </li>
            <li>
              {" "}
              Does not require any special equipment or meters installation,
              just an internet connected device is all that is needed
            </li>
          </ul>
        </div>
      ),
    },
    {
      title: "What is the technology behind BtrLyf?",
      content: (
        <div>
          <p>
            BtrLy is a digital platform to conduct virtual assessment, analysis,
            and diagnostics of building performance through digitalization. The
            AI-powered building simulation engine of BtrLyf has been developed
            through several years of Research & Development by Qi Square, a
            spin-off company of the Nanyang technological University (NTU),
            Singapore. Its designed to create digital twins for sustainability
            at the lowest cost and as quickly as possible, to then progressively
            increase the accuracy of digital twin models through data
            acquisition and machine learning (ML) techniques.
          </p>
          <img
          alt="img"
            style={{ height: 300 }}
            src={faq}
            className="rounded mx-auto d-block"
          ></img>
          <p>
            The intention of BtrLyf is to produce consistent and reliable
            evaluation and analytics of resource demand for initial green
            building assessment purposes. While BtrLyf assists decision-makers
            by providing financial analysis of their green building choices, it
            does not replace investment grade audits or complex modelling
            software, and is not envisaged to be used for system sizing.
          </p>
        </div>
      ),
    },
    {
      title: "How does BrLyf process data",
      content: (
        <div>
          <p>
            The platform aggregates multiple sources of building data, including
            open-source information, design data, standards, API linked data
            from third party providers, and user entered data to create useful
            ‘digital assets’ or ‘digital twins’. This data is then processed
            through physics-based simulation and machine-learning based
            analytics engines to deliver building specific as well as city-wide
            aggregate insights to improve performance of buildings.
          </p>
          <img
          alt="img"
            src={faq1}
            className="rounded mx-auto d-block"
            style={{ height: 300 }}
          />
        </div>
      ),
    },

    {
      title: "Is BtrLyf an open access platform?",
      content: (
        <div>
          <p>Yes. BtrLyf is available on an open cloud-based platform and users can do several tasks without even logging in. Users can do the following on full open access even without login:</p>
          <li>a) Explore the Digital Built Environment using a GIS based map of buildings in a city.</li>
          <li>b) Search sustainable buildings on the map and click on them to get a quick overview of their features and sustainability potential.</li>
          <li>c) Access a list of a huge number of sustainable buildig solutions categorized by their technical use and geography, as well as, green building experts and solution providers available in their area</li>
          </div>
      ),
    },

    {
      title: "What is purpose of Apps such as EDGAR, PRIA on BtrLyf?",
      content: (
        <div>
          <p>BtrLyf has applications (apps) especially designed to enhance the workflow, and significantly lower the costs and time required to do activities such as energy/carbon analysis, technology fit analysis, green certification assessment and financial due diligence for green projects. These applications are provided to the industry in a Software-as-a-Service (SaaS) mode, and currently include the following. Click on the product links in the website menu to know more.</p>
          <li>EDGAR: Helps in quick, low cost and fully digital assessmnet of buildings.</li>
          <li>PRIA: Helps in assessing the best and latest green building products and solutions. </li>
          <li>GRACE: Helps in achieving green certification for buildings.</li>
          <li>LISA: Helps connect to the green building industry at large</li>
        </div>
      ),
    },
    {
      title: "Is BtrLyf Free to use, or is paid?",
      content : (
        <div>
          Yes. A number of fantastic features on BtrLyf and its apps are free to use. We do however have a 'Freemium' operating model where users are charged for specialized and highly value adding services such as manual simulation in EDGAR, getting products to be qualified for asessment via PRIA, getting business leads via LISA and starting new green certification projects via GRACE. These are just some examples. Please see the full list of features under each app to review what is free and what needs to be paid for (premium). 
        </div>
      ),
    },

    {
      title: "Which cities does BtrLyf cover?",
      content : (
        <div>
          <li>Currently we are available in:</li>
          <li>Singapore</li>
          <br></br>
          <p>We are adding more cities on BtrLyf while you are reading this. This answer will be updated on a regular basis. Please check back again for the latest status or get in touch with us to discuss how and when we will get BtrLyf in your city.</p>
        </div>
      ),
    },
  ],
};

const styles = {
  bgColor: "white",
  titleTextColor: "blue",
  rowTitleColor: "black",
  rowContentColor: "grey",
  arrowColor: "blue",
};

const config = {
  animate: true,
  arrowIcon: "^",
  tabFocus: true,
};

const Faqq = () => {
  return (
    <>
      <img
        src={Faq_image}
        style={{ height: 300 }}
        className="rounded mx-auto d-block"
        alt="hi"
      ></img>
      <br />
      <Row id="Faqs">
        <Col>
          <h1 className="text-white text-center bg-dark">
            Frequently Asked Questions (FAQs)
          </h1>
        </Col>
      </Row>
      <br />
      <div className="mx-auto d-block">
        <Faq
          className="mx-auto d-block text-center"
          data={data}
          styles={styles}
          config={config}
        />
      </div>
      <br />
      <Facs />
      <Contact />
    </>
  );
};
export default Faqq;
