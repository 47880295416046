import React from "react";
import "./style.css";
import { BsCheck2 } from "react-icons/bs";
import Switch from "@mui/material/Switch";
import NewContact from "../../Components/NewContactForm/NewContact";

const label = { inputProps: { "aria-label": "Switch demo" } };
export default function NewPricing() {
  return (
    <>
      <section id="pricing">
        <div className="container">
          <h3 className="pricing-head">Pricing</h3>
          <h3 className="pricing-text">
            Choose a plan that suits for your needs
          </h3>
        </div>
        <div className="pricing-switch-container">
          <div className="pricing-box">
            <p className="monthly">Monthly</p>
            <Switch  {...label} defaultChecked />
            <p className="annualy">Annualy</p>
          </div>
        </div>
        <div className="container my-5">
          <div className="row">
            <div className="col-md-4 pricings">
              <div className="pricing-card">
                <div className="pricing-card-head">
                  <p className="card-type">Free</p>
                  <h2>$0</h2>
                  <p className="card-time">For a Year</p>
                  <button className="btn btn-primary w-100">
                    Get Started Now
                  </button>
                </div>
                <div className="pricing-card-body">
                  <p className="card-list-item">
                    <span className="card-list-icon">
                      <BsCheck2 />
                    </span>
                    Auto Simulation
                  </p>
                  <p className="card-list-item">
                    <span className="card-list-icon">
                      <BsCheck2 />
                    </span>
                    Add data{" "}
                  </p>
                  <p className="card-list-item">
                    <span className="card-list-icon">
                      <BsCheck2 />
                    </span>
                    View all building
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 pricings">
              <div className="pricing-card1">
                <div className="pricing-card-head">
                  <p className="card-type">Standard</p>
                  <h2>$99</h2>
                  <p className="card-time">For a Year</p>
                  <button className="btn btn-primary w-100">
                    Get Started Now
                  </button>
                </div>
                <div className="pricing-card-body">
                  <p className="card-list-item">
                    <span className="card-list-icon">
                      <BsCheck2 />
                    </span>
                    Auto Simulation
                  </p>
                  <p className="card-list-item">
                    <span className="card-list-icon">
                      <BsCheck2 />
                    </span>
                    Add data{" "}
                  </p>
                  <p className="card-list-item">
                    <span className="card-list-icon">
                      <BsCheck2 />
                    </span>
                    View all building
                  </p>
                  <p className="card-list-item">
                    <span className="card-list-icon">
                      <BsCheck2 />
                    </span>
                    Access One Building
                  </p>
                  <p className="card-list-item">
                    <span className="card-list-icon">
                      <BsCheck2 />
                    </span>
                    Manual Simulation for One Building
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 pricings">
              <div className="pricing-card2">
                <div className="pricing-card-head">
                  <p className="card-type1">Premium</p>
                  <h2 style={{ color: "white" }}>$999</h2>
                  <p className="card-time1">For a Year</p>
                  <button className="btn btn-primary w-100">
                    Get Started Now
                  </button>
                </div>
                <div className="pricing-card-body">
                  <p className="card-list-premium">
                    <span className="card-list-preicon">
                      <BsCheck2 />
                    </span>
                    Auto Simulation
                  </p>
                  <p className="card-list-premium">
                    <span className="card-list-preicon">
                      <BsCheck2 />
                    </span>
                    Add data{" "}
                  </p>
                  <p className="card-list-premium">
                    <span className="card-list-preicon">
                      <BsCheck2 />
                    </span>
                    View all building
                  </p>
                  <p className="card-list-premium">
                    <span className="card-list-preicon">
                      <BsCheck2 />
                    </span>
                    Access Unlimited Building
                  </p>
                  <p className="card-list-premium">
                    <span className="card-list-preicon">
                      <BsCheck2 />
                    </span>
                    Manual Simulation for Unlimited Building
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NewContact />
      </section>
    </>
  );
}
