import React from "react";
//import { AddBoxIcon } from "@material-ui/icons/AddBox";
import { Row, Col } from "reactstrap";
import { Carousel, Container } from "react-bootstrap";
import "../App.css";
import { AiFillFilePdf } from "react-icons/ai";
import screen from "../assets/BtrLyf_screens.webp";
import ecosystem from "../assets/BtrLyf_ecosystem.webp";
import table from "../assets/Industry_table.webp";
import sustainable from "../assets/SDG11.webp";


const WhyBtrlyf = () => {
  return (
    <>
      <Row id="WhyBtrlyf">
        <Col>
          <h1 className="text-white text-center bg-dark">Why Btrlyf</h1>
        </Col>
      </Row>
      <br />
      <Carousel className="carousel-dark">
        <Carousel.Item>
          <Row>
            <Col>
              <h3 className="text-dark">BTRLYF FOR THE BUILDING INDUSTRY</h3>
            </Col>
          </Row>
          <Container fluid="md">
            <Row>
              <Col>
                <img
                  src={table}
                  className="mx-auto d-block rounded"
                  style={{ height: 250 }}
                  alt="hi"
                ></img>
              </Col>

              <Col>
                <p style={{ margin: "5%" }}>
                  Btrlyf gets the industry to work together on green building
                  projects with better data exchange for enhanced productivity
                  and continuous improvements. This information can be securely
                  and yet easily accessed by the relevant stakeholders of a
                  building performance improvement project or at an aggregate
                  level by industry stakeholders in general. In effect it
                  creates a ‘virtual working/meeting table’ for multi-faceted
                  digital green assessment.
                </p>
              </Col>
              {/* <Col>
                <button>
                  <AddBoxIcon />
                </button>
              </Col> */}
            </Row>
          </Container>
        </Carousel.Item>
        <Carousel.Item>
          <Row>
            <Col>
              <h3 className="text-dark">
                BTRLYF FOR SUSTAINABLE DECARBONIZATION
              </h3>
            </Col>
          </Row>
          <Container fluid="md">
            <Row>
              <Col>
                <img
                  src={sustainable}
                  className="rounded mx-auto d-block"
                  style={{ height: 250 }}
                  alt="hi"
                ></img>
              </Col>

              <Col>
                <p style={{ margin: "5%" }}>
                  The built environment sector is the largest contributor to
                  global greenhouse gas (GHG) emissions. Buildings use about 40
                  % of global energy, 25 % of global water, 40 % of global
                  resources, and emit approximately 30 % of GHG emissions. These
                  emissions are set to double by 2050 if we carry on business as
                  usual. We will help accelerate the adoption of energy
                  efficient/sustainable solutions through digitizing the
                  industry with our AI-enabled Collaborative tech platform.
                </p>
              </Col>
            </Row>
          </Container>
        </Carousel.Item>
        <Carousel.Item>
          <Row>
            <Col>
              <h3 className="text-dark">
                BTRLYF FOR CONSULTANTS & SOLUTION PROVIDERS
              </h3>
            </Col>
          </Row>
          <Container fluid="md">
            <Row>
              <Col>
                <img
                  src={screen}
                  className="rounded mx-auto d-block"
                  style={{ height: 250 }}
                  alt="hi"
                ></img>
              </Col>

              <Col>
                <ul className="text-dark" style={{ margin: "5%" }}>
                  <li>
                    First-hand visibility in green projects through
                    quantification & qualification of energy/carbon/cost savings
                  </li>
                  <li>
                    Aggregate city-scale data insights of buyers/dealmakers
                  </li>
                  <li>
                    Market trusted & neutral platform to suggest solutions.
                  </li>
                  <li>
                    Avoids travel and reduces physical touchpoints by providing
                    secure remote digital access
                  </li>
                  <li>Does not require any special equipment or meters</li>
                </ul>
                <div className="divPdf">
                  <a title="Btrlyf for Consultants & ESCOs.pdf" href="https://sbl-resources.s3.ap-southeast-1.amazonaws.com/PDF/BTRLYF+for+Consultants+%26+ESCOs.pdf?index=true" target="_blank" rel="noreferrer">
                    <AiFillFilePdf className="iconPdf"/>
                  </a>

                  <a title="Btrlyf for Solution Providers.pdf" href="https://sbl-resources.s3.ap-southeast-1.amazonaws.com/PDF/BTRLYF+for+Solution+Providers.pdf?index=true" target="_blank" rel="noreferrer">
                    <AiFillFilePdf className="iconPdf" />
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </Carousel.Item>
        <Carousel.Item>
          <Row>
            <Col>
              <h3 className="text-dark">
                BTRLYF FOR THE BUILDING OWNERS/MANAGERS
              </h3>
            </Col>
          </Row>
          <Container fluid="md">
            <Row>
              <Col>
                <img
                  src={ecosystem}
                  className="rounded mx-auto d-block"
                  style={{ height: 250 }}
                  alt="hi"
                ></img>
              </Col>

              <Col>
                <ul className="text-dark" style={{ margin: "5%" }}>
                  <li>
                    Creation of Digital Assets/ Digital Twins of green buildings
                  </li>
                  <li>Data enabled open innovation platform</li>
                  <li>
                    Low-cost & quick assessments for green building projects
                  </li>
                  <li>Continuous performance upgrade opportunities</li>
                  <li>Neutral impact assessment and solution matching</li>
                </ul>
                <a title="BTRLYF for Asset Managers_V2.pdf" href="https://sbl-resources.s3.ap-southeast-1.amazonaws.com/PDF/BTRLYF+for+Asset+Managers_V2.pdf?index=true" target="_blank" rel="noreferrer">
                  <AiFillFilePdf style={{ color: "red", height: "50px", width: "50px", marginLeft: "380px", marginBottom: "30px" }} />
                </a>
              </Col>
            </Row>
          </Container>
        </Carousel.Item>
      </Carousel>
      <br />
    </>
  );
};

export default WhyBtrlyf;
