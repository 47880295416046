import React ,{useEffect} from "react";
import "./style.css";
import grace from "../../assets/pria-banner.png";
import graceabout from "../../assets/pria-about.png";
import feature1 from "../../assets/feature1.png";
import feature2 from "../../assets/feature2.png";
import feature3 from "../../assets/feature3.png";
import feature4 from "../../assets/feature4.png";
import feature5 from "../../assets/feature5.png";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import NewContact from "../../Components/NewContactForm/NewContact";

export default function NewPria() {
  useEffect(()=>
  {
    window.scrollTo(0, 0);
  },[])
  return (
    <>
      <section id="banner">
        <div className="grace-main">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-sm-12">
                <h3 className="grace-first-head">
                  BtrLyf <b>PRIA</b>
                </h3>
                <h2 className="grace-second-head">
                  Product Inclusion in Assessment
                </h2>
                <p className="grace-para">
                  PRIA (Product Inclusion in Assessment) helps product
                  suppliers, resellers and vendors to include their products for
                  digital assessment in our EDGAR tool.
                </p>
                <button className="grace-button">Coming soon</button>
              </div>
              <div className="col-md-4 col-sm-12">
                <img src={grace} className="img-fluid" alt="Grace banner" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about-grace">
        <div className="container">
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6">
              <h3 className="about-us-head">About PRIA</h3>
            </div>
            <div className="col-md-4 col-sm-12">
              <img src={graceabout} alt="grace about" className="img-fluid" />
            </div>
            <div className="col-md-8 col-sm-12">
              <p className="grace-about-para">
                PRIA (Product Inclusion in Assessment) helps product suppliers,
                resellers and vendors to include their products for digital
                assessment in our EDGAR tool. Currently, the best technical
                sales tool used by most solution providers and resellers is
                ‘case studies’ of other projects that have benefited from their
                solution. The quantification of benefits for the proposed new
                project are mostly done only after the project is secured or
                when an initial agreement is reached with the potential buyer.
                This quantification is done with the help of qualified internal
                technical staff or third-party experts, which increases the cost
                and time of the sales cycle.
              </p>
              <p className="grace-about-para">
                PRIA (Product Inclusion in Assessment) helps product suppliers,
                resellers and vendors to include their products for digital
                assessment in our EDGAR tool. Currently, the best technical
                sales tool used by most solution providers and resellers is
                ‘case studies’ of other projects that have benefited from their
                solution. The quantification of benefits for the proposed new
                project are mostly done only after the project is secured or
                when an initial agreement is reached with the potential buyer.
                This quantification is done with the help of qualified internal
                technical staff or third-party experts, which increases the cost
                and time of the sales cycle.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="features">
        <div className="grace-features">
          <div className="container-fuid">
            <div className="container">
              <h3 className="features-head">Features</h3>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6 feature-card-container">
                <div className="feature-card">
                  <img
                    src={feature1}
                    alt="GRACE feature"
                    className="img-fluid"
                  />
                  <p className="feature-text">Add products to PRIA</p>
                  <a href="#" className="feature-anchor">
                    Know More <IoIosArrowDropdownCircle />
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 feature-card-container">
                <div className="feature-card">
                  <img
                    src={feature2}
                    alt="GRACE feature"
                    className="img-fluid"
                  />
                  <p className="feature-text">
                    Products get ready for specific impact assessment
                  </p>
                  <a href="#" className="feature-anchor">
                    Know More <IoIosArrowDropdownCircle />
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 feature-card-container">
                <div className="feature-card">
                  <img
                    src={feature3}
                    alt="GRACE feature"
                    className="img-fluid"
                  />
                  <p className="feature-text">
                    Get the power of the network to present solutions
                  </p>
                  <a href="#" className="feature-anchor">
                    Know More <IoIosArrowDropdownCircle />
                  </a>
                </div>
              </div>
              {/* <div className="col-md-4 col-sm-6 feature-card-container">
                <div className="feature-card">
                  <img
                    src={feature4}
                    alt="GRACE feature"
                    className="img-fluid"
                  />
                  <p className="feature-text">
                    Collaboration & Team Management
                  </p>
                  <a href="#" className="feature-anchor">
                    Know More <IoIosArrowDropdownCircle />
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 feature-card-container">
                <div className="feature-card">
                  <img
                    src={feature5}
                    alt="GRACE feature"
                    className="img-fluid"
                  />
                  <p className="feature-text">
                    User Management & Access Control
                  </p>
                  <a href="#" className="feature-anchor">
                    Know More <IoIosArrowDropdownCircle />
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section id="footer">
        <div className="grace-footer">
          <NewContact />
        </div>
      </section>
    </>
  );
}
