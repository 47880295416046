import React from "react";
import Whatssnew from "../Components/Whats-new";
import advDigi from "../assets/advDigi.webp";
import qi from "../assets/qi.jpg";
import usebtrlyf from "../assets/usebtrlyf.webp";
import nilesh from "../assets/nilesh.jpg";
import ceo from "../assets/ceo.webp";
import Contact from "../Components/Contact";

const WhatsNew = () => {
  const data = [
    {
      img1: advDigi,
      img2: qi,
      title: "Qi Square",
      subtitle:
        "BtrLyf secures support under the Advanced Digital Solutions funding from IMDA Singapore",
      desc: "The BtrLyf- Digital Built Environment Ecosystem by Qi Square is now supported and is a pre-approved solution under the Advanced Digital Solutions (ADS) funding provided by the Infocomm Media Development Authority (IMDA), Singapore.Announced as part of the Resilience Budget 2020 by Singapore’s Deputy Prime Minister (DPM) and Minister for Finance Heng Swee Keat, the ADS grant funding is to help enterprises deepen their digital capabilities, strengthen business continuity measures, and build longer term resilience. See more information about ADS on IMDA’ s website and refer to the ‘Sustainability’ project category for information on the BtrLyf digital package.",
    },
    {
      img1: usebtrlyf,
      img2: nilesh,
      title: "Nilesh Y. Jadhav",
      subtitle:
        "BtrLyf secures support under the Advanced Digital Solutions funding from IMDA Singapore",
      desc: "At COP26, experts are urging commercial real estate to be more sustainable to reach crucial climate-action goals. The largest challenge for the industry is retrofitting buildings to be more sustainable. Buildings can save 20% to 60% in operating costs through proven green retrofit measures with an excellent return on investment. However, building industry players do not have the tools to easily identify and quantify energy-saving opportunities, evaluate the latest technological solutions and access financing options. They end up spending a lot of time in the administration of green projects and finding the right partners to work with.",
    },
    {
      img1: ceo,
      img2: qi,
      title: "QiSquare",
      subtitle:
        "Qi Square secures strategic investment from SoftTech Engineers Ltd.",
      desc: "SoftTech Engineers Ltd (NSEI: SOFTTECH), announced its strategic investment in Singapore-based start-up Qi Square Pte Ltd. Softtech Engineers is a leading IT company with 25 years of expertise facilitating business & technology transformation across the Architectural Engineering Construction (AEC) industry. Equipped with 25 years of deep domain expertise and industry knowledge, SoftTech has helped more than 4,500 clients in India and around the world to gain a competitive digital edge in the industry.",
    },
  ];
  return (
    <div>
      <Whatssnew data={data} />
      <Contact />
    </div>
  );
};

export default WhatsNew;
