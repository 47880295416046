import React from "react";
import { Container } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";

import logo from "../assets/newlogo.png";

export const Header = () => {
  return (
    <Container sticky="top">
      <h3>
        <Link smooth to="/#Home">
          <img src={logo} alt="logo" height="70" className="my-4"></img>&nbsp;&nbsp;
        </Link>
        <b className="text-primary" style={{fontSize:"35px"}}>Digital</b>
        <b className="text-dark" style={{fontSize:"35px"}}> Built Environment</b>
        <b className="text-success" style={{fontSize:"35px"}}> Ecosystem</b>
      </h3>
    </Container>
  );
};
