import React ,{useEffect} from "react";
import "./style.css";
import grace from "../../assets/grace-banner.png";
import graceabout from "../../assets/grace-about.png";
import feature1 from "../../assets/feature1.png";
import feature2 from "../../assets/feature2.png";
import feature3 from "../../assets/feature3.png";
import feature4 from "../../assets/feature4.png";
import feature5 from "../../assets/feature5.png";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import NewContact from "../../Components/NewContactForm/NewContact";

export default function NewGrace() {
  useEffect(()=>
  {
    window.scrollTo(0, 0);
  },[])
  return (
    <>
      <section id="banner">
        <div className="grace-main">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-sm-12">
                <h3 className="grace-first-head">
                  BtrLyf <b>GRACE</b>
                </h3>
                <h2 className="grace-second-head">Green Auto Certification</h2>
                <p className="grace-para">
                  GReen Auto CErtification (GRACE) helps streamline green
                  building certification data and get automation to work for
                  you.
                </p>
                <button className="grace-button">Coming soon</button>
              </div>
              <div className="col-md-4 col-sm-12">
                <img src={grace} className="img-fluid" alt="Grace banner" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about-grace">
        <div className="container">
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6">
              <h3 className="about-us-head">About GRACE</h3>
            </div>
            <div className="col-md-4 col-sm-12">
              <img src={graceabout} alt="grace about" className="img-fluid" />
            </div>
            <div className="col-md-8 col-sm-12">
              <p className="grace-about-para">
                {" "}
                GReen Auto CErtification (GRACE) helps streamline green building
                certification data and get automation to work for you. GRACE
                will significantly enhance the productivity of green building
                certification projects.
              </p>
              <p className="grace-about-para">
                GRACE is best suited for consultants and sustainability teams
                that are involved in green building certification projects.
                Rather can keeping data and tracking in spreadsheets and
                personal or even shared folders, GRACE provides a 24x7 access to
                the Green Building Certification project data along with score
                tracking, documentations, regulatory submission and
                collaborative secure access. See full list of features below
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="features">
        <div className="grace-features">
          <div className="container-fuid">
            <div className="container">
              <h3 className="features-head">Features</h3>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6 feature-card-container">
                <div className="feature-card">
                  <img
                    src={feature1}
                    alt="GRACE feature"
                    className="img-fluid"
                  />
                  <p className="feature-text">
                    Digital Repository for Certification Data
                  </p>
                  <a href="#" className="feature-anchor">
                    Know More <IoIosArrowDropdownCircle />
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 feature-card-container">
                <div className="feature-card">
                  <img
                    src={feature2}
                    alt="GRACE feature"
                    className="img-fluid"
                  />
                  <p className="feature-text">
                    Real-time Scorecard for Certification Projects
                  </p>
                  <a href="#" className="feature-anchor">
                    Know More <IoIosArrowDropdownCircle />
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 feature-card-container">
                <div className="feature-card">
                  <img
                    src={feature3}
                    alt="GRACE feature"
                    className="img-fluid"
                  />
                  <p className="feature-text">
                    Automatic Calculations and Instant reports
                  </p>
                  <a href="#" className="feature-anchor">
                    Know More <IoIosArrowDropdownCircle />
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 feature-card-container">
                <div className="feature-card">
                  <img
                    src={feature4}
                    alt="GRACE feature"
                    className="img-fluid"
                  />
                  <p className="feature-text">
                    Collaboration & Team Management
                  </p>
                  <a href="#" className="feature-anchor">
                    Know More <IoIosArrowDropdownCircle />
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 feature-card-container">
                <div className="feature-card">
                  <img
                    src={feature5}
                    alt="GRACE feature"
                    className="img-fluid"
                  />
                  <p className="feature-text">
                    User Management & Access Control
                  </p>
                  <a href="#" className="feature-anchor">
                    Know More <IoIosArrowDropdownCircle />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="footer">
        <div className="grace-footer">
            <NewContact />
        </div>
      </section>
    </>
  );
}
