import React,{useState} from "react";
import { HashLink as Link } from "react-router-hash-link";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import "../App.css";
import config from '../config';

const NavBar = () => {
  // const handleIndustryBuzz = () => {
  //   //let Events = `${process.env.REACT_APP_Edgar}`;
  //   //window.open(Events);
  //   window.open(config.btrlyf_blog_URL);
  // };
  const [showApp, setShowApp] = useState(false);
  const showDropdownApp = (e)=>{
      setShowApp(!showApp);
  }
  const hideDropdownApp = e => {
      setShowApp(false);
  }
  
  const [showCompany, setShowCompany] = useState(false);
  const showDropdownCompany = (e)=>{
      setShowCompany(!showCompany);
  }
  const hideDropdownCompany= e => {
      setShowCompany(false);
  }

  const [showIndustry, setShowIndustry] = useState(false);
  const showDropdownIndustry = (e)=>{
      setShowIndustry(!showIndustry);
  }
  const hideDropdownIndustry= e => {
      setShowIndustry(false);
  }

  return (
    <>
      <Navbar
        collapseOnSelect
        expand={'lg'}
        variant="light"
        bg="primary"
        className="navbar-hide-on-scroll"
      >
        <Container>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link>
                <Link to="/#home">
                  {" "}
                  <b className="text-white"  style={{marginLeft:"30px",marginRight:"30px"}}> Home</b>
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link smooth to="/#WhyBtrlyf">
                  {" "}
                  <b className="text-white" style={{marginLeft:"30px",marginRight:"30px"}}>Why Btrlyf?</b>
                </Link>
              </Nav.Link>
              <strong>
                {" "}
                <NavDropdown title="BtrLyf Apps"  style={{marginLeft:"30px",marginRight:"30px"}} id="collasible-nav-dropdown "
                 show={showApp}
                 onMouseEnter={showDropdownApp}
                 onMouseLeave={hideDropdownApp}
                >
                  <NavDropdown.Item className="ddItem">
                    <Link smooth to="/Edgar">
                      {" "}
                      <button>
                        <b>EDGAR</b>
                      </button>
                    </Link>
                  </NavDropdown.Item>

                  <NavDropdown.Item className="ddItem">
                    {/* <Link smooth to="/#Edgar"> */}
                      {" "}
                      <button  onClick={()=>window.open(config.btrlyf_URL)}>
                        <b>LISA</b>
                      </button>
                    {/* </Link> */}
                  </NavDropdown.Item>

                  <NavDropdown.Item className="ddItem">
                    <Link smooth to="/pria">
                      <button>
                        <b>PRIA</b>
                      </button>
                    </Link>
                  </NavDropdown.Item>

                  <NavDropdown.Item className="ddItem">
                    <Link smooth to="/#Edgar">
                      {" "}
                      <button>
                        <b>GRACE</b>
                      </button>
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
              </strong>
              <strong>
                <NavDropdown  style={{marginLeft:"30px",marginRight:"30px"}} title="Industry Buzz" id="collasible-nav-dropdown"
                 show={showIndustry}
                 onMouseEnter={showDropdownIndustry}
                 onMouseLeave={hideDropdownIndustry}
                >
                  <NavDropdown.Item className="ddItem">
                      <button onClick={() => {
                        window.open(config.btrlyf_blog_URL);
                        }}>
                        <b>News and Opinions </b>
                      </button>
                  );
                  </NavDropdown.Item>

                  <NavDropdown.Item className="ddItem">
                    <Link to="/Home">
                      {" "}
                      <button>
                        <b>Green Buildings Daily</b>
                      </button>
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
              </strong>
              </Nav>

              <Nav>
              <strong>
                <NavDropdown
                  className="ddItem"
                  title="Company"
                  id="collasible-nav-dropdown"
                  style={{marginLeft:"30px",marginRight:"30px"}}
                  show={showCompany}
                  onMouseEnter={showDropdownCompany}
                  onMouseLeave={hideDropdownCompany}
                >
                  <NavDropdown.Item className="ddItem">
                    <Link smooth to="/AboutUs">
                      {" "}
                      <button>
                        <b>About Us</b>
                      </button>
                    </Link>
                  </NavDropdown.Item>

                  <NavDropdown.Item className="ddItem">
                    <Link to="/Whatsnew">
                      {" "}
                      <button>
                        <b>What's New?</b>
                      </button>
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
              </strong>
              <Nav.Link>
                <Link smooth to="/#ContactUs">
                  <b className="text-white"  style={{marginLeft:"30px",marginRight:"30px"}}>Contact Us</b>
                </Link>
              </Nav.Link>
              <strong>
                <NavDropdown  style={{marginLeft:"30px",marginRight:"30px"}} title="FAQ" id="collasible-nav-dropdown">
                  <NavDropdown.Item className="ddItem">
                    <Link to="/faq">
                      {" "}
                      <button>
                        <b>FAQs</b>
                      </button>
                    </Link>
                  </NavDropdown.Item>

                  <NavDropdown.Item className="ddItem">
                    <Link to="/faq#Fac">
                      {" "}
                      <button>
                        <b>FACs</b>
                      </button>
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
              </strong>
            </Nav>

            {/* <div className="float-end">
              <Nav.Link onClick={handleSignin}>
                <b className="text-white">Sign in</b>
              </Nav.Link>
            </div> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;
