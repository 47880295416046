import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import config from '../config'

const Features = (props) => {
  const data = props.data.map((data1) => {
    return (
      <>
        <br />
        <Container className="bg-white">
          <br />
          <Row>
            <br />
            <Col style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={data1.img}
                className="rounded mx-auto d-block "
                style={{
                  height: 150,
                  border: "solid",
                  verticalAlign: " middle",
                }}
                alt="hi"
              ></img>
            </Col>
            <Col>
              <h4>{data1.subtitle}</h4>
              <p>{data1.desc}</p>
              <button
                onClick={() => {
                  data1.link();
                }}
                style={{
                  backgroundColor: data1.color,
                  color: "white",
                  width: "25%",
                  marginRight: "100px",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                }}
                className="btn"
              >
                {data1.btnName}
              </button>
            </Col>
          </Row>
          <br />
        </Container>
        <br />
        <div className="bg-light">
          <br />
          <br />
        </div>
      </>
    );
  });
  return (
    <>
      <Row>
        <Col>
          <h2 className="text-white text-center bg-dark">
            Features of SelBtrLyf-EDGAR
          </h2>
        </Col>
      </Row>
      <Container className="bg-light">
        <br />
        <Row>
          <br />
          {data}
          <br />
        </Row>

        <Row className="bg-white">
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <br />
            <br />
            <iframe
              width="300"
              height="200"
              src= {config.youtube_embed_URL}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Col>
          <Col>
            <br />
            <h4>Auto & Manual Performance Simulation</h4>
            <p>
              In a first-of-its-kind on-cloud performance simulation using
              building physics and machine-learning, get to know how building
              performance can be improved through auto-suggested or manually
              selected sustainable solutions ranging from building envelope,
              lighting to air-conditioning, controls and renewable energy.
            </p>
            <button
              onClick={() => {
                window.open (config.youtube_URL);
              }}
              style={{
                backgroundColor: "red",
                color: "white",
                width: "25%",
                marginRight: "100px",
                paddingTop: "2px",
                paddingBottom: "2px",
              }}
              className="btn"
            >
              Video Tutorial
            </button>
            <br />
            <br />
          </Col>
          <br />
          <br />
        </Row>
        <br />
      </Container>
    </>
  );
};

export default Features;
