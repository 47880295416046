import React from "react";
import "./style.css";
import pic1 from "../../assets/image54.png";
import pic2 from "../../assets/image55.png";
import arrowpic from "../../assets/ssss1.png";
import desktop from "../../assets/desktop.png";
import listpic1 from "../../assets/image56.png";
import listpic2 from "../../assets/image57.png";
import listpic3 from "../../assets/image58.png";
import listpic4 from "../../assets/image59.png";
import listpic5 from "../../assets/image62.png";
import { MdOutlinePictureAsPdf } from "react-icons/md";
import NewNewsletter from "../../Components/NewNewsletter/NewNewsletter";

export default function NewWhy() {
  return (
    <section id="NewWhy">
      <div className="new-why-banner">
        <div className="container">
          <br></br><br></br>
          <h3 className="why-head">Why Do Virtual Energy Audits ?</h3>
          <div className="why-imghead">
            <div className="row">
              {/* <div className="col-md-1"></div> */}
              <div className="col-md-3 d-flex justify-content-center">
                <img src={pic1} width="90%" alt="Why 1" />
              </div>
              <div className="col-md-1 d-flex justify-content-center align-items-center">
                <img src={arrowpic} height="150px" alt="arrow" />
              </div>
              <div className="col-md-3 d-flex justify-content-center">
                <img src={desktop} width="90%" alt="desktop screen" />
              </div>
              <div className="col-md-1 d-flex justify-content-center align-items-center">
                <img src={arrowpic} height="150px" alt="arrow" />
              </div>
              <div className="col-md-3 d-flex justify-content-center">
                <img src={pic2} width="90%" alt="Why 1" />
              </div>
            </div>
          </div>
          <div className="my-4 d-flex justify-content-center">
            <a href="https://sbl-resources.s3.ap-southeast-1.amazonaws.com/PDF/BTRLYF+for+Consultants+%26+ESCOs.pdf?index=true"><button className="btn btn-primary" style={{ width: "auto" }}>
              {" "}
              <MdOutlinePictureAsPdf /> View Case Study
            </button></a>
          </div>
        </div>
      </div>
      <div className="why-content">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <img width="70%" src={listpic3} alt="listpic3" />
            </div>
            <div className="col-md-9">
              <h3 className="whylist-head">Cost Effective</h3>
              <p className="lead">
                {" "}
                Buildings mean business, and its highly costly and tedious to
                perform energy audits on operating buildings and keep on trying
                new ways to improve performance. The virtual environment offers
                a smarter way to audit and evaluate opportunities in a quick and
                cost-effective way. It can be repeated several times without
                costing much and can offer accurate performance evaluation of
                improvements or energy saving measures much ahead of time.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-9">
              <h3 className="whylist-head">Time Effective</h3>
              <p className="lead">
                The benefits of our approach are substantial time and cost
                savings. A entire portfolio of buildings can be audited and
                prepared for retro-commissioning/retrofits within matter of
                weeks. Virtual audits are 50-70% faster and 60-80% cheaper than
                traditional on-site audits. They are also useful in accurately
                assessing the ‘whole building’ energy savings potential rather
                than only focusing on sub-systems such as the central
                chiller/heating system alone.
              </p>
            </div>
            <div className="col-md-3">
              <img width="70%" src={listpic2} alt="listpic3" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <img width="70%" src={listpic1} alt="listpic3" />
            </div>
            <div className="col-md-9">
              <h3 className="whylist-head">Holistic</h3>
              <p className="lead">
                {" "}
                The approach is more holistic and covers sub-systems and their
                interactions with the overall building performance. It can be
                also used for validating performance post retrofits and on a
                continuous basis.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-9">
              <h3 className="whylist-head">Customised</h3>
              <p className="lead">
                {" "}
                Every building is different and not all energy saving measures
                are impactful in each case. The Virtual Environment modeling and
                simulation of your building will reveal opportunities that are
                unique and customized for your business needs.
              </p>
            </div>
            <div className="col-md-3">
              <img width="70%" src={listpic4} alt="listpic4" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <img width="70%" src={listpic5} alt="listpic5" />
            </div>
            <div className="col-md-9">
              <h3 className="whylist-head">Systematic & Accurate</h3>
              <p className="lead">
                {" "}
                Buildings mean business, and its highly costly and tedious to
                perform energy audits on operating buildings and keep on trying
                new ways to improve performance. The virtual environment offers
                a smarter way to audit and evaluate opportunities in a quick and
                cost-effective way. It can be repeated several times without
                costing much and can offer accurate performance evaluation of
                improvements or energy saving measures much ahead of time.
              </p>
            </div>
          </div>
        </div>
      </div>
      <NewNewsletter />
    </section>
  );
}
