import { useEffect, useState } from "react";
// import { BrowserRouter} from "react-router-dom";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import "./App.css";
import { Header } from "./Components/Header";
import Home from "./Pages/Home";
import NotFound from "./Components/NotFound";
import NavBar from "./Components/NavBar";
import Edgar from "./Pages/Edgar";
import Pria from "./Pages/Pria";
import WhatsNew from "./Pages/WhatsNew";

import Faqq from "./Components/Faq";
import AboutUs from "./Components/AboutUs";
import Facs from "./Components/Facs";
import Topbar from "./Components/Topbar/Topbar";
import config from "./config";
import NewScreen from "./Pages/NewUi/NewScreen";
import NewPricing from "./Pages/NewPrincing/NewPricing";
import NewAboutUs from "./Pages/NewAboutUs/NewAboutUs";
import NewPria from "./Pages/NewPria/NewPria";
import NewEdgar from "./Pages/NewEdgar/NewEdgar";
import NewGrace from "./Pages/NewGrace/NewGrace";
import Form from "react-bootstrap/Form";
import NewNavbar from "./Components/NewNavbar/NewNavbar";
import NewWhy from "./Pages/NewWhy/NewWhy";
import NewFAQ from "./Pages/NewFAQ/NewFAQ";
import NewHome from "./Pages/NewHome/NewHome";
import NewFooter from "./Components/NewFooter/NewFooter";
import Blog from "./Pages/Blog/Blog";

function Routes(props) {
  const history = useHistory();
  console.log(props.Uivalue);
  useEffect(() => {
    if (props.Uivalue === 1) {
      history.push("/");
    } else {
      history.push("/new-ui");
    }
  });
  return (
    <>
      {/* <Topbar /> */}
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/edgar" component={Edgar} exact />
        <Route path="/pria" component={Pria} exact />
        <Route path="/whatsnew" component={WhatsNew} exact />
        <Route path="/faq" component={Faqq} exact />
        <Route path="/AboutUs" component={AboutUs} exact />
        <Route path="/new-ui" component={NewScreen} exact />
        <Route path="/new-edgar" component={NewEdgar} exact />
        <Route path="/new-pria" component={NewPria} exact />
        <Route path="/new-whatsnew" component={WhatsNew} exact />
        <Route path="/new-faq" component={NewFAQ} exact />
        <Route path="/new-why" component={NewWhy} exact />
        <Route path="/new-AboutUs" component={NewAboutUs} exact />
        <Route path="/new-pricing" component={NewPricing} exact />
        <Route path="/new-grace" component={NewGrace} exact />
        <Route path="/new-blog" component={Blog} exact />
        <Route component={NotFound} />
      </Switch>
    </>
  );
}

export default Routes;
