import React from "react";
import "./style.css";

export default function NewNewsletter() {
  return (
    <>
      <div className="newsletter-main">
        <p className="newsletter-text">
          We are raising an investment round right now.
        </p>
        <p className="newsletter-text">
          Invest in the future of green buildings.
        </p>
        <div className="newsletter-input-box">
            <input type='email' placeholder="Enter email address" />
            <button  className="newsletter-button">Join Us</button>
        </div>
      </div>
    </>
  );
}
