import { Button, Card, Carousel, Col, Container, Row } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";
import map1 from "../assets/map1.png";
import a from "../assets/a.webp";
import b from "../assets/b.webp";
import c from "../assets/c.webp";
import d from "../assets/d.webp";
import f from "../assets/f.webp";
import config from '../config';

export const Map = () => {
  const handleVideo = () => {
    //let Events = `${process.env.REACT_APP_Grace}`;
    //window.open(Events);
    window.open(config.youtube_URL);
  };
  const img = { backgroundImage: `url(${map1}` };
  return (
    <>
      <br />
      <div>
        <div style={img}>
          {/* <img
          className="opacity-50"
          src={map}
          alt="map"
          width="100%"
          height="300"
         ></img> */}
          <div>
            <Row>
              <br />
              <Col>
                <br />
                <Card className="bg-dark text-white myCard">
                  <Card.Body>
                    <Card.Text>
                      {" "}
                      <h3 className="text-primary">BtrLyf</h3> (pronounced
                      ‘better life’) is an open and unified digital platform
                      that helps co-creation of sustainable buildings by
                      enabling collaboration and AI-enabled assessment of green
                      buildings and related solutions, instantly and at super
                      low costs.
                    </Card.Text>
                  </Card.Body>
                  <Card.Body>
                    <div
                      className="btn-toolbar d-flex justify-content-center "
                      role="toolbar"
                      aria-label="Toolbar with button groups"
                    >
                      <div
                        className="btn-group mx-2"
                        role="group"
                        aria-label="First group"
                      >
                        <Link smooth to="#WhyBtrlyf">
                          <Button
                            className=" btn rounded-pill"
                            // onClick={handleKnowMore}
                            style={{
                              borderColor: "blue",
                              backgroundColor: "black",
                            }}
                          >
                            Know More
                          </Button>
                        </Link>
                      </div>
                      <div
                        className="btn-group mx-2"
                        role="group"
                        aria-label="Second group"
                      >
                        <Button
                          onClick={handleVideo}
                          className=" btn rounded-pill"
                          style={{
                            borderColor: "green",
                            backgroundColor: "Black",
                            width: "200px",
                            marginRight: "10px",
                          }}
                        >
                          Watch a Video
                        </Button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
                <br />
              </Col>
              <Col>
                <Carousel>
                  <Carousel.Item style={{ border: "2px" }}>
                    <Container fluid="md">
                      <Row>
                        <Col>
                          <br />
                          <img
                            src={a}
                            className="rounded mx-auto d-block"
                            style={{ height: 300 }}
                            alt="hi"
                          ></img>
                          <br />
                        </Col>
                      </Row>
                    </Container>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Container fluid="md">
                      <Row>
                        <Col>
                          <br />
                          <img
                            src={b}
                            className="rounded mx-auto d-block"
                            style={{ height: 300 }}
                            alt="hi"
                          ></img>
                          <br />
                        </Col>
                      </Row>
                    </Container>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Container fluid="md">
                      <Row>
                        <Col>
                          <br />
                          <img
                            src={c}
                            className="rounded mx-auto d-block"
                            style={{ height: 300 }}
                            alt="hi"
                          ></img>
                          <br />
                        </Col>
                      </Row>
                    </Container>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Container fluid="md">
                      <Row>
                        <Col>
                          <br />
                          <img
                            src={d}
                            className="rounded mx-auto d-block"
                            style={{ height: 300 }}
                            alt="hi"
                          ></img>
                          <br />
                        </Col>
                      </Row>
                    </Container>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Container fluid="md">
                      <Row>
                        <Col>
                          <br />
                          <img
                            src={f}
                            className="rounded mx-auto d-block"
                            style={{ height: 300 }}
                            alt="hi"
                          ></img>
                          <br />
                        </Col>
                      </Row>
                    </Container>
                  </Carousel.Item>
                </Carousel>
              </Col>
              <br />
            </Row>
          </div>
        </div>
      </div>

      <br />
    </>
  );
};
