import Button from "@restart/ui/esm/Button";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import "../App.css";
import pria from "../assets/PRIA.webp";
import { HashLink as Link } from "react-router-hash-link";

import lisa from "../assets/LISA.webp";
import grace from "../assets/GRACE_JPG.webp";
import edgar from "../assets/EDGAR.webp";
import config from '../config';


const OurApps = (props) => {
  const handleLisaKnowMore = () => {
    //let Events = `${process.env.REACT_APP_Edgar}`;
    //window.open(Events);
    window.open(config.btrlyf_feature_URL);
  };
  const handleLisa = () => {
    //let Events = `${process.env.REACT_APP_Edgar}`;
    //window.open(Events);
    window.open(config.btrlyf_URL);
  };
  const handleGraceKnowMore = () => {
    //let Events = `${process.env.REACT_APP_Edgar}`;
    //window.open(Events);
    window.open(config.gracefeature_URL);
  };
  const handleGrace = () => {
    //let Events = `${process.env.REACT_APP_Edgar}`;
    //window.open(Events);
    window.open(config.gracr_URL);
  };
  const handleEdgar = () => {
    //let Events = `${process.env.REACT_APP_Edgar}`;
    //window.open(Events);
    window.open(
      config.edgar_URL
    );
  };
  const handleEdgarKnowmore = () => {
    window.alert("feature is coming soon");
  };
  //SR:18May::Comented this and hard coded this column

  // const data = props.obj.map((data1) => {
  //   return (
  //     <Col>
  //       <Card className="myCard">
  //         <Card.Body>
  //           <Card.Title style={{ color: data1.color, fontSize: "30px" }}>
  //             <b>{data1.title}</b>
  //           </Card.Title>
  //           <Card.Subtitle className="mb-2 text-dark">
  //             {data1.subtitle}
  //           </Card.Subtitle>
  //           <Card.Img
  //             style={{ height: "auto", width: "auto" }}
  //             variant="top"
  //             src={data1.img}
  //           />
  //           <Card.Text> {data1.desc} </Card.Text>
  //         </Card.Body>
  //         <Card.Body>
  //           <Row>
  //             <Col>
  //               <Link to={data1.link}>
  //                 <Button
  //                   className=" btn rounded-pill"
  //                   // onClick={() => {
  //                   //   data1.know();
  //                   // }}
  //                   style={{ borderColor: data1.color, maxWidth: 150 }}
  //                 >
  //                   Know More
  //                 </Button>
  //               </Link>
  //             </Col>
  //             <Col>
  //               <Button
  //                 className=" btn rounded-pill text-white"
  //                 onClick={() => {
  //                   data1.dive();
  //                 }}
  //                 style={{
  //                   backgroundColor: data1.color,
  //                   maxWidth: 150,
  //                 }}
  //               >
  //                 {data1.btnName}
  //               </Button>
  //             </Col>
  //           </Row>
  //         </Card.Body>
  //       </Card>
  //     </Col>
  //   );
  // });

  return (
    <>
      <Row>
        {/* {data} */}
  {/* SR:18May::hard coded this column */}
        <Col>
          <Card className="myCard">
            <Card.Body>
              <Card.Title style={{ color: "#6089cd", fontSize: "30px" }}>
                <b>EDGAR</b>
              </Card.Title>
              <Card.Subtitle className="mb-2 text-dark">
              Enhanced Digital Green Assessor
              </Card.Subtitle>
              <Card.Img
                style={{ height: "auto", width: "auto" }}
                variant="top"
                src={edgar}
              />
              <Card.Text>
              EDGAR increases productivity of a green assessment by 10-50x it allows for Do-it-Yourself (DIY) technical analysis in green projects without the need for deep technical skills.It also prevents duplication of efforts that are common in the industry due to lack of an effective technical data sharing structure.
              </Card.Text>
            </Card.Body>
            <Card.Body>
              <Row>
                <Col>
                  <Link smooth to="/edgar">
                    <Button
                      className=" btn rounded-pill"
                      onClick={handleEdgarKnowmore}
                      style={{ borderColor: "#6089cd", maxWidth: 150 }}
                    >
                      Know More
                    </Button>
                  </Link>
                </Col>
                <Col>
                  <Link smooth to="/#ContactUs">
                    <Button
                      className=" btn rounded-pill text-white"
                      onClick={handleEdgar}
                      style={{
                        backgroundColor: "#6089cd",
                        maxWidth: 150,
                      }}
                    >
                      Dive in
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <br />
          <br />
        </Col>
        <Col>
          <Card className="myCard">
            <Card.Body>
              <Card.Title style={{ color: "#cd007f", fontSize: "30px" }}>
                <b>PRIA</b>
              </Card.Title>
              <Card.Subtitle className="mb-2 text-dark">
                Product Inclusion in Assessment
              </Card.Subtitle>
              <Card.Img
                style={{ height: "auto", width: "auto" }}
                variant="top"
                src={pria}
              />
              <Card.Text>
                PRIA helps product suppliers, resellers and vendors to include
                their products for digital assessment. It allows for
                quantification and qualification of solutions to a higher degree
                of granularity and AI-enabled matching. Very effective digital
                tool for honing the selection process for products in green
                projects.Click on the know more button for more details.
              </Card.Text>
            </Card.Body>
            <Card.Body>
              <Row>
                <Col>
                  <Link smooth to="/pria">
                    <Button
                      className=" btn rounded-pill"
                      style={{ borderColor: "#cd007f", maxWidth: 150 }}
                    >
                      Know More
                    </Button>
                  </Link>
                </Col>
                <Col>
                  <Link smooth to="/#ContactUs">
                    <Button
                      className=" btn rounded-pill text-white"
                      style={{
                        backgroundColor: "#cd007f",
                        maxWidth: 150,
                      }}
                    >
                      Contact
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <br />
          <br />
        </Col>
      </Row>
      <Row>
        {/* <br /> */}
        <Col>
          {/* <br /> */}
          <Card className="myCard">
            <Card.Body>
              <Card.Title style={{ color: "blue", fontSize: "30px" }}>
                <b>LISA</b>
              </Card.Title>
              <Card.Subtitle className="mb-2 text-dark">
                Listing of Industry Specialist App
              </Card.Subtitle>
              <Card.Img
                style={{ minWidth: "250px",maxWidth:"350px"}}
                variant="top"
                src={lisa}
              />
              <Card.Text>
                Specially curated listing and project/product showcases for
                sustainable built environment. You can find and connect to
                thousands of green building industry specialists by their area
                and geography of work.
              </Card.Text>
            </Card.Body>
            <Card.Body>
              <Row>
                <Col>
                  <Button
                    className=" btn rounded-pill"
                    onClick={handleLisaKnowMore}
                    style={{ borderColor: "blue", maxWidth: 150 }}
                  >
                    Know More
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={handleLisa}
                    className=" btn rounded-pill text-white"
                    style={{
                      backgroundColor: "#082e7be0",
                      maxWidth: 150,
                    }}
                  >
                    Dive In
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <br />
          <br />
        </Col>
        <Col>
          {/* <br /> */}
          <Card className="myCard">
            <Card.Body>
              <Card.Title style={{ color: "#59d659", fontSize: "30px" }}>
                <b>GRACE</b>
              </Card.Title>
              <Card.Subtitle className="mb-2 text-dark">
                Green Auto Certification tool
              </Card.Subtitle>
              <Card.Img
                style={{ height: 190, width: "auto" }}
                variant="top"
                src={grace}
              />
              <Card.Text>
                Streamline green building certification data and get automation
                to work for you. GRACE will significantly enhance the
                productivity of green building certification projects.
              </Card.Text>
            </Card.Body>
            <Card.Body>
              <Row>
                <Col>
                  <Button
                    className=" btn rounded-pill"
                    // onClick={() => {
                    //   data1.know();
                    // }}
                    onClick={handleGraceKnowMore}
                    style={{ borderColor: "green", maxWidth: 150 }}
                  >
                    Know More
                  </Button>
                </Col>
                <Col>
                  <Button
                    className=" btn rounded-pill text-white"
                    onClick={handleGrace}
                    style={{ maxWidth: 150, backgroundColor: "#59d659" }}
                  >
                    Try Beta
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <br />
          <br />
        </Col>
      </Row>
    </>
  );
};

export default OurApps;
