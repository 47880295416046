import React from "react";
import Features from "../Components/Features";
import SelEdgar from "../Components/SelEdgar";
import mybuildings from "../assets/mybuildings.webp";
import search from "../assets/search_compare.webp";
import city from "../assets/city_build_map.webp";
import add_data from "../assets/add_data.webp";
import Contact from "../Components/Contact";
import { AiOutlineDownload } from "react-icons/ai";
import config from "../config";

const Edgar = () => {
  const handleEdgar = () => {
    //let Events = `${process.env.REACT_APP_Grace}`;
    //window.open(Events);
    window.open(config.edgar_URL);
  };
  const handleVideo1 = () => {
    //let Events = `${process.env.REACT_APP_Grace}`;
    //window.open(Events);
    window.open("https://www.youtube.com/watch?v=gKkEXZNnPNc");
  };
  const handleVideo2 = () => {
    //let Events = `${process.env.REACT_APP_Grace}`;
    //window.open(Events);
    window.open("https://www.youtube.com/watch?v=6mrBNs-f518");
  };
  const handleVideo3 = () => {
    //let Events = `${process.env.REACT_APP_Grace}`;
    //window.open(Events);
    window.open("https://www.youtube.com/watch?v=DfGI2n0v6Yo");
  };
  // const handleVideo4 = () => {
  //   //let Events = `${process.env.REACT_APP_Grace}`;
  //   //window.open(Events);
  //   window.open("https://www.youtube.com/watch?v=ulL_QtbR-Oo");
  // };
  const handleVideo5 = () => {
    //let Events = `${process.env.REACT_APP_Grace}`;
    //window.open(Events);
    window.open("https://www.youtube.com/watch?v=6mrBNs-f518");
  };

  const data = [
    {
      key: 0,
      title: "EDGAR",
      img: city,
      subtitle: "Open Access Digital Twins for Sustainability Assessment",
      desc: "Explore the Digital Built Environment using a GIS based map of buildings in a city. Search sustainable buildings on the map and click on them to get a quick overview of their features and sustainability potential. Get custom alerts on changes in building information and 'claim buildings' to start working on improvement projects",
      color: "red",
      btnName: "Video Tutorial",
      link: handleVideo1,
    },
    {
      key: 1,
      title: "PRIA",
      subtitle: "Search, Compare & Benchmark Building Performance",
      img: search,
      desc: "Users can also 'Search' buildings using multiple criteria such as building typology, gross floor area, year built and sustainability performance (energy use intensity, energy savings potential, etc.). The 'Compare'  feature allows to compare up to 4 buildings at a time and instantly generate and download a comparison report e.g. for project and presentation work.",
      color: "red",
      btnName: "Video Tutorial",
      link: handleVideo2,
    },
    {
      key: 2,
      title: "EDGAR",
      img: add_data,
      subtitle: "Add Data to Buildings",
      desc: "Add buildings data and securely collect data in an intuitive and well organized format that also performs calculations on the fly so you do not need to keep on adding into each data field individually. Add basic or advanced data and leave the rest to our Machine Learning algorithms to generate a baseline performance report for the building instantly.",
      color: "red",
      btnName: "Video Tutorial",
      link: handleVideo3,
    },

    {
      key: 4,
      title: "GRACE",
      subtitle: "User Management & Access Control",
      img: mybuildings,
      desc: " Although BtrLyf is an open platform, it is designed with maximum security and data privacy in mind. Building owners/managers can invite only the relevant partners to work on their performance improvement projects along with them. They can also decide what data is to be shared openly and what data is to be kept confidential. The secure APIs can connect to further services only with appropriate authentications on both ends. Furthermore, by adding new partners to the platform and allowing them secure login access, the repetitive task of sharing data with multiple parties can be avoided altogether. ",
      color: "red",
      btnName: "Video Tutorial",
      link: handleVideo5,
    },
  ];

  return (
    <div className="bg-light">
      <SelEdgar />
      <Features data={data} />
      <div className="d-flex justify-content-center ">
        <button
          onClick={handleEdgar}
          style={{
            backgroundColor: "red",
            width: "20%",
            color: "white",
          }}
          className="btn"
        >
          Start with Edgar for free
        </button>
      </div>
      <br />
      <p className="text-center">
        Download Whitepaper:{" "}
        <b>
          Using BtrLyf-EDGAR for Instant Digital Green Assessment of Buildings
        </b>
        {"   "}
        <a
          title="Btrlyf for Consultants & ESCOs.pdf"
          href="https://sbl-resources.s3.ap-southeast-1.amazonaws.com/PDF/BtrLyf_Whitepaper_Oct2021.pdf?"
          target="_blank"
          rel="noreferrer"
        >
          <AiOutlineDownload
            style={{
              border: "solid",
              height: "40px",
              color: "black",
              width: "40px",
            }}
          />
        </a>
      </p>

      <Contact />
    </div>
  );
};

export default Edgar;
