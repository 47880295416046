import React from "react";
import { Row, Col } from "reactstrap";
import { Carousel, Container } from "react-bootstrap";
import "../App.css";

import bank from "../assets/bank.webp";
import malls from "../assets/malls.webp";
import Servers from "../assets/Servers.webp";
import TanTC from "../assets/TanTC.webp";
import Paul_Booij from "../assets/Paul_Booij.webp";

const Testimonials = () => {
  return (
    <div className="p-3 mb-2 bg-dark text-white">
      <Row id="WhyBtrlyf">
        <Col>
          <h1 className="text-white text-center bg-dark">Testimonials and References</h1>
        </Col>
      </Row>
      <br />
      <Carousel>
        <Carousel.Item>
          <Container fluid="md">
            <Row>
              <Col className="text-center" >
                <p><b>Simulation Expert</b></p>
                <img
                  src={Paul_Booij}
                  className="rounded mx-auto d-block"
                  style={{ height: 250 }}
                  alt="hi"
                ></img>
                <br></br>
                <p><b>Paul Booij</b></p>
                <p><b>Founder & Managing Director,</b></p>
                <p><b>Optimulate</b></p>
              </Col>

              <Col>
                <br></br>
                <br></br>
                <h5 className="mt-0">
                <p style={{ margin: "5%" }}>
                  “Consultants spend too much time on addressing customer
                  queries on multiple energy and cost savings scenarios and
                  evaluating green products. BtrLyf provides a much quicker
                  (even instant) and very low-cost solution to do an initial
                  assessment that can be a good ‘foot-in-the-door’ for a bigger
                  opportunity.”
                  
                </p>
                </h5>
              </Col>
            </Row>
          </Container>
        </Carousel.Item>
        <Carousel.Item>
          <Container fluid="md">
            <Row>
              <Col className="text-center">
              <p><b>Building Industry</b></p>
              <p><b>Veteran</b></p>
                <img
                  src={TanTC}
                  className="rounded mx-auto d-block"
                  style={{ height: 250 }}
                  alt="hi"
                ></img>
                <br></br>
                <p><b>Tan Tian Chong</b></p>
                <p><b>Managing Partner, Aptiv8</b></p>
              </Col>

              <Col>
              <br></br>
              <br></br>
              <br></br>
              <h5 className="mt-0">
                <p style={{ margin: "5%" }}>
                  “Digital tools like BtrLyf are disrupting the green building
                  industry and putting all professionals on their toes to work
                  smarter and adopt digitalization to succeed or otherwise
                  struggle much harder to remain relevant in today’s day and
                  age.”
                </p>
                </h5>
              </Col>
            </Row>
          </Container>
        </Carousel.Item>
        <Carousel.Item>
          <Container fluid="md">
            <Row>
              <Col className="text-center">
              <p><b>Banking Portfolio</b></p>
                <img
                  src={bank}
                  className="rounded mx-auto d-block"
                  style={{ height: 250 }}
                  alt="hi"
                ></img>
                <br></br>
                <p><b>Singapore</b></p>
              </Col>

              <Col>
              <br></br>
              <br></br>
              <h5 className="mt-0">
                <ul className="text-white" style={{ margin: "5%" }}>
                  <li>Client: Large Private Bank </li>
                  <li>No. of Buildings: 15</li>
                  <li>Building Type: Mixed-use</li>
                  <li>
                    Mixed-use Location: Singapore Assessed GFA: 111,000 Sq.m.
                  </li>
                  <li>Portfolio Energy Savings: 28% ($1.2 mil./yr)</li>
                  <li>Max Saving in a Building: 58%</li>
                </ul>
                </h5>
              </Col>
            </Row>
          </Container>
        </Carousel.Item>
        <Carousel.Item>
          <Container fluid="md">
            <Row>
              <Col className="text-center">
              <p><b>Retail Portfolio</b></p>
                <img
                  src={malls}
                  className="rounded mx-auto d-block"
                  style={{ height: 250 }}
                  alt="hi"
                ></img>
                <br></br>
                <p><b>Singapore</b></p>
              </Col>

              <Col>
              <br></br>
              <br></br>
              <h5 className="mt-0">
                <ul className="text-white" style={{ margin: "5%" }}>
                  <li>Client: Shopping Mall Operator </li>
                  <li>No. of Buildings: 6</li>
                  <li>Building Type: Retail</li>
                  <li>Assessed GFA: 310,000 Sq.m.</li>
                  <li>Portfolio Energy Savings: 32% ($4.7 mil./yr)</li>
                  <li>Max Saving in a Building: 43%</li>
                </ul>
                </h5>
              </Col>
            </Row>
          </Container>
        </Carousel.Item>
        <Carousel.Item>
          <Container fluid="md">
            <Row>
              <Col className="text-center">
              <p><b>Data Centers</b></p>
                <img
                  src={Servers}
                  className="rounded mx-auto d-block"
                  style={{ height: 250 }}
                  alt="hi"
                ></img>
                <br></br>
                <p><b>Singapore</b></p>
              </Col>

              <Col>
              <br></br>
              <br></br>
              <h5 className="mt-0">
                <ul className="text-white" style={{ margin: "5%" }}>
                  <li>Client: Telecom provider</li>
                  <li>No. of Buildings: 2</li>
                  <li>Building Type: Data Centres</li>
                  <li>Assessed GFA: 86,000 Sq.m.</li>
                  <li>Portfolio Energy Savings: 14%</li>
                  <li>Max Saving in a Building: 20%</li>
                </ul>
                </h5>
              </Col>
            </Row>
          </Container>
        </Carousel.Item>
      </Carousel>
      <br />
    </div>
  );
};

export default Testimonials;
