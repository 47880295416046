import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import logo from "../../assets/newlogo.png";
import config from "../../config";
import "./style.css";
import { Link } from "react-router-dom";
import MenuDropDown from "./MenuDropDown";

export default function NewNavbar() {
  return (
    <>
      <div className="container-fluid">
        <Navbar expand="lg" className="new-navbar">
          {/* <div className="new-navbar"> */}
          <Container className="px-5">
            <Navbar.Brand>
              <Link to="/new-ui">
                <img src={logo} alt="logo" height="70"></img>&nbsp;&nbsp;
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto ">
                {/* <div className="new-nav-items"> */}
                <div className="menu-item">
                  <Link to="/new-why">Why Btrlyf</Link>
                </div>
                {/* <div className="menu-item">
                  <NavDropdown
                    title={<span style={{ color: "#0088d6" }}>Solutions</span>}
                    id="collasible-nav-dropdown"
                    style={{ color: "#0088d6" }}
                    //  show={showApp}
                    //  onMouseEnter={showDropdownApp}
                    //  onMouseLeave={hideDropdownApp}
                  >
                    <NavDropdown.Item className="menu-dropdown-item">
                      <Link to="/new-edgar">
                        <p className="btrlyf-apps">EDGAR (Asses)</p>
                      </Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item className="menu-dropdown-item">
                      <p
                        className="btrlyf-apps"
                        onClick={() => window.open(config.btrlyf_URL)}
                      >
                        LISA (Market Listing)
                      </p>
                    </NavDropdown.Item>

                    <NavDropdown.Item className="menu-dropdown-item">
                      <Link to="/new-pria">
                        <p className="btrlyf-apps">PRIA (Qualify)</p>
                      </Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item className="menu-dropdown-item">
                      <Link smooth to="/new-grace">
                        <p className="btrlyf-apps">GRACE (Certify)</p>
                      </Link>
                    </NavDropdown.Item>
                  </NavDropdown>
                </div> */}
                {/* <div className="menu-item">
                    <Link to="/new-pricing">Pricing</Link>
                  </div> */}
                <div>{<MenuDropDown />}</div>
                <div className="menu-item">
                  <Link to="/new-AboutUs">About Us</Link>
                </div>
                <div className="menu-item">
                  <Link to="/new-faq">FAQs</Link>
                </div>

                {/* <div className="menu-item">
              <Link to="new-whatsnew">What's New</Link>
            </div> */}
                {/* </div> */}
              </Nav>
            </Navbar.Collapse>
            {/* </div> */}
          </Container>
        </Navbar>
      </div>
    </>
  );
}
