import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import config from '../config'
import edgar from "../assets/EDGAR.webp";

const SelEdgar = () => {
  const handleEdgar = () => {
    //let Events = `${process.env.REACT_APP_Grace}`;
    //window.open(Events);
    window.open(config.edgar_URL);
  };
  return (
    <div>
      <br />
      <h2 className="text-primary text-center"> SelBtrLyf-EDGAR</h2>
      <br />
      <Row className="  d-flex">
        <Col>
        <br></br>
          <img
            src={edgar}
            className="rounded mx-auto d-block"
            style={{ height: 300, borderStyle:"solid" }}
            alt="hi"
          ></img>
        </Col>
        <Col style={{ marginRight: 30 }}>
          <Container>
            <p style={{ textJustify: "inter-word" }}>
              <b>EDGAR (Enhanced Digital Green Assessor)</b>, increases
              productivity of a green assessment by 10-50x. it allows for
              Do-it-Yourself (DIY) technical analysis in green projects without
              the need for deep technical skills. It also prevents duplication
              of efforts that are common in the industry due to lack of an
              effective technical data sharing structure.
            </p>
            <p style={{ textJustify: "inter-word" }}>
              {" "}
              ​EDGAR is best suited for{" "}
              <b>
                building owners/facility managers, sustainability managers and
                Environmental Sustainable Design (ESD) consultants
              </b>
              , who would like to do pre-qualification and initial assessment of
              buildings. It includes benchmarking, baseline report generation,
              auto and manual simulation of a wide range of Energy Conservation
              Measures (ECMs). One EDGAR license allows to do multiple building
              simulations and at multiple times as and when required. See full
              list of features below
            </p>
          </Container>
        </Col>
      </Row>
      <br />
      <div className="d-flex justify-content-center ">
        <button
          onClick={handleEdgar}
          style={{
            backgroundColor: "red",
            width: "20%",
            color: "white",
            paddingTop: "2px",
            paddingBottom: "2px",
          }}
          className="btn"
        >
          Start with Edgar for free
        </button>
      </div>
      <br />
    </div>
  );
};

export default SelEdgar;
