import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { SocialIcon } from "react-social-icons";
import "../App.css";
import HubspotForm from "react-hubspot-form";
import config from "../config";

const Contact = () => {
  return (
    <>
      <br />
      <br></br>
      <Container
        id="ContactUs"
        fluid
        className="App"
        style={{ background: "black" }}
      >
        <br />
        <br />
        <fieldset>
          <legend
            style={{
              textAlign: "center",
              fontSize: "40px",
              letterSpacing: "6px",
              color: "white",
            }}
          >
            <b>CONTACT US</b>
          </legend>
        </fieldset>
        <br />
        <br />
        <Row>
          <Col lg={4} md={2} sm={12}></Col>
          <Col lg={4} md={8} sm={12}>
            <HubspotForm
              portalId="6028177"
              formId="a160d94e-741e-4773-8fd1-3d3197c55e0e"
              onSubmit={() => console.log("Submit!")}
              onReady={(form) => console.log("Form ready!")}
              loading={<div>Loading...</div>}
            />
          </Col>
          <Col lg={4} md={2} sm={12}></Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col lg={6} md={6} sm={12}>
            <p style={{ color: "white", textAlign : "center" }}>
              {" "}
              Address: 20 Cecil Street, #05-03 PLUS, Singapore 049705
            </p>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <p style={{ color: "white", textAlign : "center" }}>
              Email: admyn@btrlyf.com
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={12} style={{ textAlign : "center" }}>
            <span>
              {" "}
              <SocialIcon url= {config.linkedin_URL}/>
            </span>
            <span>
              {" "}
              <SocialIcon url= {config.youtube_UCWpPA_URL} />
            </span>
            <span>
              {" "}
              <SocialIcon url={config.facebook_URL} />
            </span>
            <span>
              {" "}
              <SocialIcon url= {config.twitter_URL} />
            </span>
            <span>
              {" "}
              <SocialIcon url={config.instragram_URL} />
            </span>
          </Col>
          <Col lg={6} md={6} sm={12}></Col>
        </Row>{" "}
        <br />
        <Row>
          <Col lg={6} md={6} sm={12}>
          <p style={{ textAlign : "center", color: "white" }}>
            © 2021 by Qi Square Pte Ltd (UEN 201716434E)
          </p>
          </Col>
          <Col lg={6} md={6} sm={12}></Col>
        </Row>
      </Container>
    </>
  );
};

export default Contact;