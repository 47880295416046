import React ,{useState} from "react";
import "./style.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import NewContact from "../../Components/NewContactForm/NewContact";
import faq from "../../assets/faq.jpg";
import faq1 from "../../assets/faq1.jpg";

export default function NewFAQ() {
  const [first, setfirst] = useState("");
  const [unit, setunit] = useState("");
  function handleSubmit(e) {
    e.preventDefault();
    const { number, calc } = e.target.elements;
    console.log({ number: number.value, calculate: calc.value });
    setfirst(number.value);
    setunit(calc.value);
  }
  return (
    <>
      <div>
        <div className="container my-3">
          <h1 className="Faq-heading">
            <span className="underline">FAQ'</span>s
          </h1>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <Accordion>
                <AccordionSummary
                  style={{ background: "#EDF9FD", width:"100%" }}
                  expandIcon={<IoIosArrowDropdownCircle />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    How to pronounce 'BtrLyf'? What does it stand for?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      'BtrLyf' is a pronounced as 'Better Life'. BtrLyf is a
                      registered trademark of Qi Square Pte Ltd. We named our
                      platform <br></br>
                      'BtrLyf' as it aims to create a better life for all of us
                      by creating a btr lyf for people in-charge of making btr
                      buildings.<br></br>
                      We do that through digitalization and collaborative
                      artificial intelligence. Btr buildings save energy and
                      costs, reduce <br></br>
                      carbon emissions, and create a sustainable built
                      environment lyf for everyone!
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  style={{ background: "#EDF9FD", width:"100%" }}
                  expandIcon={<IoIosArrowDropdownCircle />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>What problem does it solve?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      Buildings consume 30% of energy and contribute to 40% of
                      carbon emissions globally. By 2050, the world’s building
                      stock will double, increasing the impact on climate
                      significantly. Its demand on natural resources accelerates
                      climate change, and inefficient, unhealthy buildings are
                      affecting our livelihoods.
                    </p>
                    <p>
                      Buildings can save 20% to 60% in operating costs through
                      proven green retrofit measures with an excellent return on
                      investmnet. However, building industry players do not have
                      the tools to easily identify and quantify energy saving
                      opportunities, evaluate the latest technological solutions
                      and access financing options. They end up spending a lot
                      of time in administration of green projects and finding
                      the right partners to work with.
                    </p>
                    <p>
                      The lack of effective data accessibility and exchange
                      inhibits the growth of the industry. This in turn severely
                      prohibits timely deployment of successful decarbonization
                      strategies that are urgently required to mitigate and
                      limit climate change and global warming impacts.{" "}
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  style={{ background: "#EDF9FD", width:"100%" }}
                  expandIcon={<IoIosArrowDropdownCircle />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>What exactly does BtrLyf do? </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      BtrLyf gets the industry (building owners/managers,
                      consultants, tech providers, ESCOs, financiers, etc.) to
                      work together on green building projects with better data
                      exchange for enhanced productivity and continuous
                      improvement. It is an unified digital platform that helps
                      co-creation of sustainable buildings by enabling
                      collaboration and AI-enabled assessment of green buildings
                      and related solutions.
                    </p>
                    <br></br>
                    <p>
                      By increasing the productivity of green projects through
                      digitalization and collaboration, BtrLyf enables the
                      acceleration of super low energy and zero energy buildings
                      and contributes positively to the decarbonization of the
                      built environment. The resultant impact for the industry
                      includes reduced transaction time, lower marketing costs,
                      faster sales cycles, lower investment risk and improved
                      productivity of business processes by up to 10x. In short,
                      BtrLyf is a green building market platform that helps
                      accelerate sustainable development through digitalization.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  style={{ background: "#EDF9FD", width:"100%" }}
                  expandIcon={<IoIosArrowDropdownCircle />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>
                    What are the benefits of using BtrLyf?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      Through digitalization, BtrLyf eliminates a lot of issues
                      associated with manual work and disconnected workflows in
                      green building projects. The benefits of using BtrLyf are
                      varied and it helps different industry partners in
                      different ways. Below are the just some of the key
                      benefits achived for the industry:
                    </p>
                    <p>
                      <ul>
                        <li>
                          {" "}
                          Eliminates the need for expensive middlepersons for
                          effective deal execution
                        </li>
                        <li>
                          {" "}
                          Allows for Do-it-Yourself technical analysis without
                          the need for technical skills
                        </li>
                        <li>
                          {" "}
                          Prevents duplication of efforts that are common in the
                          industry due to lack of relevant data exchange and
                          archiving
                        </li>
                        <li>
                          {" "}
                          Avoids any travel or reduces physical touchpoints by
                          providing secure remote digital access to green
                          building data
                        </li>
                        <li>
                          {" "}
                          Provides low-cost and instant assessment and solution
                          fit analysis
                        </li>
                        <li>
                          {" "}
                          Offers continuous performance upgrade and business
                          development opportunities
                        </li>
                        <li>
                          {" "}
                          Provides a technically sound, validated, and neutral
                          green assessment
                        </li>
                        <li>
                          {" "}
                          Takes advantage of open-source data and connected data
                          from 3rd party APIs
                        </li>
                        <li>
                          {" "}
                          Integrates with different data sources and conducts a
                          comprehensive assessment
                        </li>
                        <li>
                          {" "}
                          Does not require any special equipment or meters
                          installation, just an internet connected device is all
                          that is needed
                        </li>
                      </ul>
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  style={{ background: "#EDF9FD", width:"100%" }}
                  expandIcon={<IoIosArrowDropdownCircle />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>What is the technology behind BtrLyf?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      BtrLy is a digital platform to conduct virtual assessment,
                      analysis, and diagnostics of building performance through
                      digitalization. The AI-powered building simulation engine
                      of BtrLyf has been developed through several years of
                      Research & Development by Qi Square, a spin-off company of
                      the Nanyang technological University (NTU), Singapore. Its
                      designed to create digital twins for sustainability at the
                      lowest cost and as quickly as possible, to then
                      progressively increase the accuracy of digital twin models
                      through data acquisition and machine learning (ML)
                      techniques.
                    </p>
                    <p>
                      <img
                        alt="img"
                        style={{ height: 300 }}
                        src={faq}
                        className="rounded mx-auto d-block img-fluid"
                      ></img>
                    </p>
                    <p>
                      The intention of BtrLyf is to produce consistent and
                      reliable evaluation and analytics of resource demand for
                      initial green building assessment purposes. While BtrLyf
                      assists decision-makers by providing financial analysis of
                      their green building choices, it does not replace
                      investment grade audits or complex modelling software, and
                      is not envisaged to be used for system sizing.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  style={{ background: "#EDF9FD", width:"100%" }}
                  expandIcon={<IoIosArrowDropdownCircle />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>How does BrLyf process data</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      The platform aggregates multiple sources of building data,
                      including open-source information, design data, standards,
                      API linked data from third party providers, and user
                      entered data to create useful ‘digital assets’ or ‘digital
                      twins’. This data is then processed through physics-based
                      simulation and machine-learning based analytics engines to
                      deliver building specific as well as city-wide aggregate
                      insights to improve performance of buildings.
                    </p>
                    <p>
                      <img
                        alt="img"
                        style={{ height: 300 }}
                        src={faq1}
                        className="rounded mx-auto d-block img-fluid"
                      ></img>
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  style={{ background: "#EDF9FD", width:"100%" }}
                  expandIcon={<IoIosArrowDropdownCircle />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Is BtrLyf an open access platform?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      Yes. BtrLyf is available on an open cloud-based platform
                      and users can do several tasks without even logging in.
                      Users can do the following on full open access even
                      without login:
                    </p>
                    <ul>
                      <li>
                        {" "}
                        Explore the Digital Built Environment using a GIS based
                        map of buildings in a city.
                      </li>
                      <li>
                        {" "}
                        Search sustainable buildings on the map and click on
                        them to get a quick overview of their features and
                        sustainability potential.
                      </li>
                      <li>
                        {" "}
                        Access a list of a huge number of sustainable buildig
                        solutions categorized by their technical use and
                        geography, as well as, green building experts and
                        solution providers available in their area
                      </li>
                    </ul>
                    <p>
                      After logging in, the BtrLy platform becomes more useful
                      with features such as:
                    </p>
                    <ul>
                      <li>
                        {" "}
                        Get custom alerts on changes in building information and
                        'claim buildings' to start working on improvement
                        projects
                      </li>
                      <li>
                        {" "}
                        Contact and connect to sustainability champions and
                        solution/service providers
                      </li>
                      <li>
                        {" "}
                        Conduct free analysis to evaluate the energy and carbon
                        reduction potential of any building.
                      </li>
                    </ul>
                    <iframe
                      width="100%"
                      height="450"
                      src="https://www.youtube.com/embed/6mrBNs-f518"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                    <p>
                      Although BtrLyf is an open platform, it is designed with
                      maximum security and data privacy in mind. Review the
                      features details in our Apps pages on the website to
                      understand more in this.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  style={{ background: "#EDF9FD", width:"100%" }}
                  expandIcon={<IoIosArrowDropdownCircle />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>
                    What is purpose of Apps such as EDGAR, PRIA on BtrLyf?"
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      BtrLyf has applications (apps) especially designed to
                      enhance the workflow, and significantly lower the costs
                      and time required to do activities such as energy/carbon
                      analysis, technology fit analysis, green certification
                      assessment and financial due diligence for green projects.
                      These applications are provided to the industry in a
                      Software-as-a-Service (SaaS) mode, and currently include
                      the following. Click on the product links in the website
                      menu to know more.
                    </p>
                    <li>
                      EDGAR: Helps in quick, low cost and fully digital
                      assessmnet of buildings.{" "}
                    </li>
                    <li>
                      PRIA: Helps in assessing the best and latest green
                      building products and solutions.
                    </li>
                    <li>
                      GRACE: Helps in achieving green certification for
                      buildings.{" "}
                    </li>
                    <li>
                      LISA: Helps connect to the green building industry at
                      large
                    </li>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  style={{ background: "#EDF9FD", width:"100%" }}
                  expandIcon={<IoIosArrowDropdownCircle />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Is BtrLyf Free to use, or is paid?"</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      Yes. A number of fantastic features on BtrLyf and its apps
                      are free to use. We do however have a 'Freemium' operating
                      model where users are charged for specialized and highly
                      value adding services such as manual simulation in EDGAR,
                      getting products to be qualified for asessment via PRIA,
                      getting business leads via LISA and starting new green
                      certification projects via GRACE. These are just some
                      examples. Please see the full list of features under each
                      app to review what is free and what needs to be paid for
                      (premium).
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  style={{ background: "#EDF9FD", width:"100%" }}
                  expandIcon={<IoIosArrowDropdownCircle />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Which cities does BtrLyf cover?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <li>Currently we are available in:</li>
                    <li>Singapore</li>
                    <li>
                      We are adding more cities on BtrLyf while you are reading
                      this. This answer will be updated on a regular basis.
                      Please check back again for the latest status or get in
                      touch with us to discuss how and when we will get BtrLyf
                      in your city.{" "}
                    </li>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <h1 className="unit-heading">
          <b>
            Unit Conve<span className="underline">rsions</span>
          </b>
        </h1>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-5 unitBox">
                <h4 className="unitBox-heading">kW/ton to COP to EER</h4>
                <input
                  className="form-control"
                  placeholder="1.000"
                  type={"text"}
                ></input>
                <p className="text">Convert form</p>
                <select
                  className="form-control mb-3"
                  required
                  style={{ background: "white" }}
                  aria-label="Default select example"
                  name=""
                  id="calc"
                >
                  <option value="kW/hr">kW/hr</option>
                  <option value="MBtu">MBtu</option>
                  <option value="MJ">MJ (Mega Joules)</option>
                </select>
                <div className="unitBox-result">
                  <div className="result-left">
                    <p className="result-cop">COP</p>
                    <span className="result-text">
                      Coefficient of performance
                    </span>
                    <p className="result-cop">3.517</p>
                  </div>
                  <div className="result-right">
                    <p className="result-cop">EER</p>
                    <span className="result-text">energy efficiency ratio</span>
                    <p className="result-cop">120</p>
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-5 unitBox">
                <h4 className="unitBox-heading">Energy Units</h4>
                <input
                  className="form-control"
                  placeholder="1.000"
                  type={"text"}
                ></input>
                <p className="text">Convert form</p>
                <select
                  className="form-control mb-3"
                  required
                  style={{ background: "white" }}
                  aria-label="Default select example"
                  name=""
                  id="calc"
                >
                  <option value="kW/hr">kW/hr</option>
                  <option value="MBtu">MBtu</option>
                  <option value="MJ">MJ (Mega Joules)</option>
                </select>
                <div className="unitBox-result">
                  <div className="result-left">
                    <p className="result-cop">MBtu</p>
                    <span className="result-text">
                      Coefficient of performance
                    </span>
                    <p className="result-cop">0.341</p>
                  </div>
                  <div className="result-right">
                    <p className="result-cop">MJ</p>
                    <span className="result-text">Million Btu</span>
                    <p className="result-cop">360</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="faq-contact">
        <NewContact />
      </div>
    </>
  );
}
