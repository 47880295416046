import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { Button, Container } from "react-bootstrap";

const Facs = () => {
  const [first, setfirst] = useState("");
  const [unit, setunit] = useState("");
  function handleSubmit(e) {
    e.preventDefault();
    const { number, calc } = e.target.elements;
    console.log({ number: number.value, calculate: calc.value });
    setfirst(number.value);
    setunit(calc.value);
  }
  return (
    <>
      <Row id="Fac">
        <Col>
          <h1 className="text-white text-center bg-dark">
            Frequently Asked Conversions (FACs)
          </h1>
        </Col>
      </Row>
      <Container>
        <br></br>
        <div style={{ backgroundColor: "#6c757d5e" }}>
          <h4>kW/ton to COP to EER</h4>
          Convert from
        </div>
        <br></br>

        <form onSubmit={handleSubmit}>
          <input
            //type="text"
            className="form-control"
            required
            style={{ background: "white" }}
            type="number"
            id="number"
          />
          <br />
          <select
            className="form-control"
            required
            style={{ background: "white" }}
            aria-label="Default select example"
            name=""
            id="calc"
          >
            <option value="kW/ton">kW/ton</option>
            <option value="COP">COP</option>
            <option value="EER">EER</option>
          </select>
          <br />
          <div className="text-center">
            <Button
              className=" rounded-pill"
              style={{
                width: "10%",
                backgroundColor: "#0a0a0a",
                borderColor: "#000000",
              }}
              type="submit"
            >
              Calculate
            </Button>
          </div>
        </form>
        <br />
        {(() => {
          switch (unit) {
            case "COP":
              const kWton = 12 / (first * 3.412);
              const EER = first * 3.412;
              return (
                <div style={{ backgroundColor: "#6c757d5e" }}>
                  <p>
                    <b>kW/ton</b> <br />
                    <b> {kWton}</b>{" "}
                  </p>
                  <p>
                    <b>EER (energy efficiency ratio)</b> <br /> <b>{EER}</b>{" "}
                  </p>
                </div>
              );

            case "EER":
              const kWton1 = 12 / first;
              const COP2 = first / 3.412;
              return (
                <div style={{ backgroundColor: "#6c757d5e" }}>
                  <p>
                    <b>kW/ton</b> <br></br>
                    <b>{kWton1}</b>{" "}
                  </p>
                  <p>
                    <b>COP (coefficient of performance)</b> <br /> <b>{COP2}</b>{" "}
                  </p>
                </div>
              );
            case "kW/ton":
              const COP = 12 / (first / 3.412);
              const EER2 = 12 / first;
              return (
                <div style={{ backgroundColor: "#6c757d5e" }}>
                  <p>
                    <b>COP (coefficient of performance)</b> <br />
                    <b>{COP} </b>
                  </p>
                  <p>
                    <b>EER (energy efficiency ratio)</b> <br /> <b>{EER2}</b>{" "}
                  </p>
                </div>
              );

            default:
              return <div></div>;
          }
        })()}

        <></>
        <br />
        <div style={{ backgroundColor: "#6c757d5e" }}>
          <h4>Energy Units</h4>
          Convert from
        </div>
        <br></br>

        <form onSubmit={handleSubmit}>
          <input
            //type="text"
            className="form-control"
            required
            style={{ background: "white" }}
            type="number"
            id="number"
          />
          <br />
          <select
            className="form-control"
            required
            style={{ background: "white" }}
            aria-label="Default select example"
            name=""
            id="calc"
          >
            <option value="kW/hr">kW/hr</option>
            <option value="MBtu">MBtu</option>
            <option value="MJ">MJ (Mega Joules)</option>
          </select>
          <br />
          <div className="text-center">
            <Button
              className=" rounded-pill"
              style={{
                width: "10%",
                backgroundColor: "#0a0a0a",
                borderColor: "#000000",
              }}
              type="submit"
            >
              Calculate
            </Button>
          </div>
        </form>
        <br />
        {(() => {
          switch (unit) {
            case "kW/hr":
              const MJ = 3.6 * first;
              const Mbtu = first * 0.003;
              return (
                <div style={{ backgroundColor: "#6c757d5e" }}>
                  <p>
                    <b>MJ (Mega Joules)</b> <br />
                    <b> {MJ}</b>{" "}
                  </p>
                  <p>
                    <b> Mbtu</b> <br /> <b>{Mbtu}</b>{" "}
                  </p>
                </div>
              );

            case "MBtu":
              const kWhr = first / 0.003;
              const Mj = first / 1055.06;
              return (
                <div style={{ backgroundColor: "#6c757d5e" }}>
                  <p>
                    <b>kWhr</b> <br></br>
                    <b>{kWhr}</b>{" "}
                  </p>
                  <p>
                    <b>MJ (Mega Joules)</b> <br /> <b>{Mj}</b>{" "}
                  </p>
                </div>
              );
            case "MJ":
              const kWhr1 = first / 3.6;
              const Mbtu2 = first * 1055.06;
              return (
                <div style={{ backgroundColor: "#6c757d5e" }}>
                  <p>
                    <b>kWhr</b> <br />
                    <b>{kWhr1} </b>
                  </p>
                  <p>
                    <b>Mbtu</b> <br /> <b>{Mbtu2}</b>{" "}
                  </p>
                </div>
              );

            default:
              return <div></div>;
          }
        })()}
      </Container>
    </>
  );
};

export default Facs;
