import React from "react";
import NewContact from "../../Components/NewContactForm/NewContact";
import NewFooter from "../../Components/NewFooter/NewFooter";
import NewNavbar from "../../Components/NewNavbar/NewNavbar";
import NewNewsletter from "../../Components/NewNewsletter/NewNewsletter";
import NewAboutUs from "../NewAboutUs/NewAboutUs";
import NewEdgar from "../NewEdgar/NewEdgar";
import NewFAQ from "../NewFAQ/NewFAQ";
import NewGrace from "../NewGrace/NewGrace";
import NewHome from "../NewHome/NewHome";
import NewPria from "../NewPria/NewPria";
import NewPricing from "../NewPrincing/NewPricing";
import NewWhy from "../NewWhy/NewWhy";

export default function NewScreen() {
  return (
    <>
      {/* <NewNavbar/> */}
      {/* <NewNewsletter/> */}
      {/* <NewContact /> */}
      {/* <NewAboutUs/> */}
      {/* <NewGrace /> */}
      {/* <NewPria /> */}
      {/* <NewPricing /> */}
      {/* <NewWhy /> */}
      {/* <NewEdgar /> */}
      {/* <NewFAQ/> */}
      <NewHome/>
      {/* <NewWhy /> */}
      {/* <NewFooter/> */}
    </>
  );
}
