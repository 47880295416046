import React from "react";
import { Col, Row } from "react-bootstrap";

const Whatssnew = (props) => {
  const data = props.data.map((data1) => {
    return (
      <>
        <Row>
          <Col>
            <div className="bg-white">
              <br />
              <Row>
                <br />
                <Col>
                  <img
                    src={data1.img1}
                    className="rounded mx-auto d-block"
                    style={{ height: 350 }}
                    alt="hi"
                  ></img>
                </Col>
                <Col>
                  <div className="d-inline-flex p-2 bd-highlight">
                    <img
                      src={data1.img2}
                      className="rounded  float-left"
                      style={{ height: 30 }}
                      alt="hi"
                    ></img>{" "}
                    <h6 style={{ marginLeft: 10, fontSize: "0.8rem" }}>
                      {data1.title}
                    </h6>
                  </div>
                  <h5>{data1.subtitle}</h5>
                  <p style={{ fontSize: "0.8rem" }}>{data1.desc}</p>
                </Col>
              </Row>
              <br />
            </div>
          </Col>
        </Row>
      </>
    );
  });
  return <Row>{data}</Row>;
};

export default Whatssnew;
