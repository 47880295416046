import React from "react";
import { Row, Col } from "reactstrap";
import { Container } from "react-bootstrap";
import aboutus1 from "../assets/aboutus1.PNG";
import Nitesh_J from "../assets/Nitesh_J.PNG";
import Rupesh_U from "../assets/Rupesh_U.PNG";
import Girish_R from "../assets/Girish_R.PNG";
import aboutus2 from "../assets/aboutus2.PNG";
import aboutus3 from "../assets/aboutus3.PNG";
import softtech from "../assets/softtech.PNG";
import Contact from "./Contact";

const AboutUs = () => {
  return (
    <>
      <Row id="AboutUs">
        <Col>
          <h2 className="text-blue text-center">
            <b>About Us</b>
          </h2>
        </Col>
      </Row>
      <Container>
        <br></br>
        <Row>
          <Col>
            <img src={aboutus1} style={{ height: 350 }} alt="hi"></img>
          </Col>

          <Col>
            <p>
              Qi Square Pte Ltd is a Singapore based company, setup in June
              2017, and focusing on the digitalization of sustainable built
              environment. As a spin-off company of the prestigious Nanyang
              Technological University (NTU), Singapore, it has a strong
              technological background and its founders have more than two
              decades of experience each in the domains of green buildings,
              energy modelling and simulation, performance assessment/auditing
              and project financing.
            </p>
            <p>
              The company has received technology development grants and support
              from several Singapore government agencies such as the Enterprise
              Singapore, Building and Construction Authority (BCA), Energy
              Market Authority (EMA) and JTC Corporation. The company has been
              able to attract large corporate clients such as DBS Bank, Singtel
              and Mercatus in Singapore as well as international clients and
              partners such as a Witteveen Bos (the Netherlands) and the
              Department of Energy (DOE) of Philippines.{" "}
            </p>
            <p>
              The company has been recognized as an emerging start-up leader and
              innovator in the region and has been featured in the Energy CIO
              Insight’s ‘Top-10 Energy Tech Providers APAC 2019’ listing, ‘APAC
              25 Global Cleantech 2021’ listing by Cleantech Group. It has
              recently also has been added to the list of KPMG’s latest ‘Real
              Estate Innovations Overview 2021’.
            </p>
            <p>
              The company recently (on 1st September 2021) officially launched
              its ‘BtrLyf- Digital Built Environment’ Ecosystem platform in the
              backdrop of the need for urgent global climate action and the
              Singapore Green Plan 2030 (announced in Feb 2021), which calls for
              buildings in Singapore to achieve aggressive performance
              improvement targets and green certification by 2030. BtrLyf also
              helps significantly in improving productivity and reducing costs
              of achieving the UN Sustainable Development Goals (SDGs)
            </p>
          </Col>
        </Row>
      </Container>
      <br></br>
      <hr></hr>
      <br></br>

      <div>
        <h2 className="text-dark text-center">
          <b>Our Leadership</b>
        </h2>
      </div>

      <Container>
        <br></br>
        <Row>
          <p>Mr. Nilesh Jadhav</p>
          <p>co-Founder and CEO</p>
          <Col>
            <img src={Nitesh_J} style={{ height: 300 }} alt="hi"></img>
          </Col>

          <Col>
            <p>
              Mr. Nilesh Jadhav is an energy technopreneur and industry
              influencer. He worked at the Energy Research Institute at NTU
              where he headed the EcoCampus RD&D initiative and also the
              Sustainable Building Technologies research program. He was the
              project lead for developing Singapore’s first Technology Roadmap
              in the area of Energy Efficient Building Technologies with the
              Building Construction Authority (BCA) of Singapore.
            </p>
            <p>
              He has served as a Board member of Singapore Green Building
              Council (SGBC), management council member of the Sustainable
              Energy Association of Singapore (SEAS) and a member of the Energy
              National Standards Committee (ENSC) at SPRING Singapore. He has
              written a book titled ‘Green & Smart Buildings: Advanced
              Technology Options’ and authored several conference & research
              papers.
            </p>
          </Col>
        </Row>
      </Container>
      <br></br>

      <Container>
        <br></br>
        <Row>
          <Col>
            <img src={Rupesh_U} style={{ height: 300 }} alt="hi"></img>
          </Col>

          <Col>
            <p>
              Mr. Rupesh Umtol is a qualified Mechanical Engineer with 20 years
              experience and demonstrated history of working in MEPF Building
              Designs, Green buildings, Energy Efficient systems. He has been
              running an independent engineering (M&E) consultancy firm working
              on several projects involving design and construction of
              commercial and industrial buildings in India. Also has good
              experience in commissioning and retrofitting services.
            </p>
            <p>
              Being a MEPF Consultant and Sustainability Specialist for more
              than a decade, He has been whole heartedly committed to
              Sustainability & Climate Responsive Designs. Have considerable
              expertise in developing High Performance Design strategies both
              for New-Building & Retrofit projects.
            </p>
          </Col>
        </Row>
      </Container>
      <br></br>

      <Container>
        <br></br>
        <Row>
          <Col>
            <img src={Girish_R} style={{ height: 300 }} alt="hi"></img>
          </Col>
          <Col>
            <p>
              Girish is a seasoned sustainability professional. In his decade
              long experience, he has lead award winning green projects across
              sectors, working with new and operational manufacturing
              industries, FDIs, retail chains, healthcare, hospitality,
              governments, institutions, etc. He is adept at design of
              state-of-art green buildings, one of which caught the attention of
              and received recognition from Mr. Bill Gates.
            </p>
            <p>
              As an instrumentation engineer, Girish also follows the automation
              and innovation space closely. He has worked on multiple
              mathematical and simulation models to provide solutions for IAQ
              and thermal comfort maintenance whilst saving significant energy.
              As an IESVE authorized trainier, member of standards drafting
              committee at IGBC and a sought-after lecturer at premier
              institutions, Girish helps bridge the academia-industry gap.
            </p>
          </Col>
        </Row>
      </Container>

      <hr></hr>
      <Container>
        <br></br>
        <Row>
          <Col>
            <img src={softtech} style={{ height: 100 }} alt="hi"></img>
          </Col>
          <Col>
            <div>
              <h2 className="text-dark text-center">
                <b>Strategic Partner</b>
              </h2>
            </div>
            <p>
              SoftTech Engineers Limited (NSEI: SOFTTECH) is a leading IT
              company facilitating business and technology transformation across
              the AEC industry through innovative software products and
              solutions. Equipped with 25 years of deep domain expertise and
              industry knowledge, SoftTech has helped more than 4500 clients in
              India and around the world to gain a competitive edge and lead
              from the front in the industry.
            </p>
            <p>
              In September 2021, SoftTech announced their strategic investment
              in Qi Square Pte Ltd. This strategic and technology alliance marks
              the combined synergies of the two companies in extending the
              BtrLyf platform. See more details here.
            </p>
          </Col>
        </Row>
      </Container>
      <br></br>
      <hr></hr>
      <div>
        <h2 className="text-dark text-center">
          <b>Our Customers and Partners</b>
        </h2>
      </div>
      <div>
        <img
          className="rounded mx-auto d-block"
          src={aboutus2}
          style={{ height: 500 }}
          alt="hi"
        ></img>
      </div>
      <br></br>
      <Container>
        <Row>
          <br />
          <Col>
            <br />
            <hr />
            <img
              className="rounded mx-auto d-block"
              src={aboutus3}
              style={{ height: 200 }}
              alt="hi"
            ></img>
          </Col>
        </Row>
      </Container>
      <br />
      <Contact />
    </>
  );
};

export default AboutUs;
