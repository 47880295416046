import React from "react";
import "./style.css";
import edgar from "../../assets/edgar.png";
import priaabout from "../../assets/priaabout.png";
import graceabout from "../../assets/graceabout.png";
import LISA from "../../assets/LISA.webp";
import image17 from "../../assets/image17.png";
import logo from "../../assets/newlogo.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { AiFillFilePdf } from "react-icons/ai";
import TanTC from "../../assets/TanTC.webp";
import Paul_Booij from "../../assets/Paul_Booij.webp";
import NewContact from "../../Components/NewContactForm/NewContact";
import Group2942_macbookair13_front1 from "../../assets/Group2942_macbookair13_front1.png";
import Group2940 from "../../assets/Group2940.png";
import malls from "../../assets/malls.webp";
import bank from "../../assets/bank.webp";
import Servers from "../../assets/Servers.webp";
import btrlyf_blog from "../../assets/btrlyf_blog.webp";
import { width } from "@mui/system";
import { AiOutlineHeart } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function NewHome() {
  return (
    <>
      <div className="home-main">
        <div className="bgImage">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-sm-6">
                <div className="btrlyf-text">
                  <b>BtrLyf </b>(Better Life) for
                </div>
                <div className="text_t">
                  <p>
                    SUSTAINABLE <b>DECARBONIZATION</b>
                  </p>
                </div>
                <p>
                  AI-enabled assessment and marketplace platform to plan and
                  achieve super-low energy, net zero and green certified
                  buildings, quickly and at super-low costs.
                </p>

                <img
                  src={Group2940}
                  className="rounded d-block"
                  width={"450px"}
                  alt="hi"
                ></img>
              </div>
              <div className="col-md-4  col-sm-6">
                <img
                  src={Group2942_macbookair13_front1}
                  className="w-100"
                  alt="hi"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-services">
        Our <span className="underline">Services</span>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 col-sm-12">
            <div className="card card-service">
              <div className="card-body">
                <div>
                  <h5 className="card-title">EDGAR</h5>
                  <p className="text">Enhanced Digital Green Assessor</p>
                  <img className="card-img-top" src={edgar}></img>
                  <p className="card-text">
                    EDGAR increases productivity of a green assessment by
                    10-50x. it allows for Do-it-Yourself (DIY) technical
                    analysis in green projects without the need for deep
                    technical skills. It also prevents duplication of efforts
                    that are common in the industry due to lack of an effective
                    technical data sharing structure.
                  </p>
                </div>
                <div className="card-button">
                  <Link to="/new-edgar" className="btn btn-outline-primary">
                    Know more
                  </Link>

                  <a
                    href="https://edgar.btrlyf.ai/"
                    target="_blank"
                    className="btn btn-primary"
                  >
                    Open EDGAR
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-3 col-sm-12">
            <div className="card card-service">
              <div className="card-body">
                <div>
                  <h5 className="card-title">LISA</h5>
                  <p className="text">Listing of Industry Specialist App</p>
                  <img className="card-img-top" src={LISA}></img>
                  <p className="card-text">
                    Specially curated listing and project/product showcases for
                    sustainable built environment. You can find and connect to
                    thousands of green building industry specialists by their
                    area and geography of work.
                  </p>
                </div>
                <div className="card-button">
                  <a
                    href="https://list.btrlyf.com/"
                    target="blank"
                    className="btn btn-outline-primary"
                  >
                    Know more
                  </a>
                  <a
                    href="https://list.btrlyf.com/"
                    target="blank"
                    className="btn btn-primary"
                  >
                    Open LISA
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-3 col-sm-12">
            <div className="card card-service">
              <div className="card-body">
                <div>
                  <h5 className="card-title">PRIA</h5>
                  <p className="text">Product Inclusion in Assessment</p>
                  <img className="card-img-top" src={priaabout}></img>
                  <p className="card-text">
                    PRIA helps product suppliers, resellers and vendors to
                    include their products for digital assessment. It allows for
                    quantification and qualification of solutions to a higher
                    degree of granularity and AI-enabled matching. Very
                    effective digital tool for honing the selection process for
                    products in green projects..
                  </p>
                </div>
                <div className="card-button">
                  <Link to="/new-pria" className="btn btn-outline-primary">
                    Know more
                  </Link>

                  <a href="#" className="btn btn-primary">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-3 col-sm-12">
            <div className="card card-service">
              <div className="card-body">
                <div>
                  <h5 className="card-title">GRACE</h5>
                  <p className="text">Green Auto Certification tool</p>
                  <img
                    className="card-img-top"
                    src={graceabout}
                    alt="Card image cap"
                  ></img>
                  <p className="card-text">
                    Streamline green building certification data and get
                    automation to work for you. GRACE will significantly enhance
                    the productivity of green building certification projects.
                  </p>
                </div>
                <div className="card-button">
                  <Link to="/new-grace" className="btn btn-outline-primary">
                    Know more
                  </Link>

                  <a href="#" className="btn btn-primary">
                    Coming Soon
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="whybt-bg">
        <div className="container">
          <div className="WhyBtrlyf-heading">
            Why<span className="underline"> BtrLyf ?</span>
          </div>
          <div className="d-flex">
            <div className="col-md-3">
              <img className="img-btrlyf" alt="img" src={image17}></img>
            </div>

            <div classname="col-md-9">
              <Accordion className="accordian-s">
                <AccordionSummary
                  expandIcon={<IoIosArrowDropdownCircle />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    BTRLYF FOR SUSTAINABLE DECARBONIZATION
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      The built environment sector is the largest contributor to
                      global greenhouse gas (GHG) emissions. Buildings use about
                      30 % of global energy, 25 % of global water, 40 % of
                      global resources, and emit approximately 40 % of GHG
                      emissions. These emissions are set to double by 2050 if we
                      carry on business as usual. We help accelerate the
                      adoption of energy efficient/sustainable solutions through
                      digitizing the industry ecosystem with our AI-enabled
                      collaborative tech platform.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion className="accordian-s">
                <AccordionSummary
                  expandIcon={<IoIosArrowDropdownCircle />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>BTRLYF MEANS DATA ACCESS FOR NET-ZERO</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      BtrLyf creates accessible 'Digital Twins' of buildings
                      with a data technology stack of open-source databases,
                      building industry standards, data APIs with building
                      services and processing of the data in our building
                      physics and AI engine to drive valuable insights. This
                      dats is made available to the industry stakeholders (e.g.
                      building managers, consultants/architects, green solution
                      vendors, certification bodies etc.) through an open yet
                      secure data access platform.{" "}
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion className="accordian-s">
                <AccordionSummary
                  expandIcon={<IoIosArrowDropdownCircle />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>BTRLYF FOR NET-ZERO PROJECTS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      Btrlyf gets the industry to work together on green and
                      net-zero building projects with better data exchange for
                      enhanced productivity and continuous improvements through
                      the following:
                    </p>
                    <ul>
                      <li>Low-cost instant green assessment</li>
                      <li>Scenario simulation with impact quantification</li>
                      <li>Smart solution matching</li>
                      <li>Instant reports and data archiving</li>
                      <li>
                        Data APIs from different tools and industry services
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion className="accordian-s">
                <AccordionSummary
                  expandIcon={<IoIosArrowDropdownCircle />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    BTRLYF FOR THE BUILDING OWNERS/MANAGERS
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <ul>
                      <li>
                        Creation of Digital Assets/ Digital Twins of green
                        buildings
                      </li>
                      <li>Data enabled open innovation platform</li>
                      <li>
                        Low-cost & quick assessments for green building projects
                      </li>
                      <li>Continuous performance upgrade opportunities</li>
                      <li>Neutral impact assessment and solution matching</li>
                    </ul>
                    <a
                      title="BTRLYF for Asset Managers_V2.pdf"
                      href="https://sbl-resources.s3.ap-southeast-1.amazonaws.com/PDF/BTRLYF+for+Asset+Managers_V2.pdf?index=true"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <AiFillFilePdf
                        style={{
                          color: "red",
                          height: "50px",
                          width: "50px",
                          marginLeft: "380px",
                          marginBottom: "30px",
                        }}
                      />
                    </a>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion className="accordian-s">
                <AccordionSummary
                  expandIcon={<IoIosArrowDropdownCircle />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    BTRLYF FOR CONSULTANTS & SOLUTION PROVIDERS
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <ul>
                      <li>
                        First-hand visibility in green projects through
                        quantification & qualification of energy/carbon/cost
                        savings
                      </li>
                      <li>
                        Aggregate city-scale data insights of buyers/dealmakers
                      </li>
                      <li>
                        Market trusted & neutral platform to suggest solutions.
                      </li>
                      <li>
                        Avoids travel and reduces physical touchpoints by
                        providing secure remote digital access.{" "}
                      </li>
                      <li>Does not require any special equipment or meters </li>
                    </ul>
                    <div className="divPdf">
                      <a
                        title="Btrlyf for Consultants & ESCOs.pdf"
                        href="https://sbl-resources.s3.ap-southeast-1.amazonaws.com/PDF/BTRLYF+for+Consultants+%26+ESCOs.pdf?index=true"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <AiFillFilePdf className="iconPdf" />
                      </a>

                      <a
                        title="Btrlyf for Solution Providers.pdf"
                        href="https://sbl-resources.s3.ap-southeast-1.amazonaws.com/PDF/BTRLYF+for+Solution+Providers.pdf?index=true"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <AiFillFilePdf className="iconPdf" />
                      </a>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <div className="What-heading">
        What They Say <span className="underline">About Us</span>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="says-bg">
              <p style={{ margin: "5%" }}>
                “Consultants spend too much time on addressing customer queries
                on multiple energy and cost savings scenarios and evaluating
                green products. BtrLyf provides a much quicker (even instant)
                and very low-cost solution to do an initial assessment that can
                be a good ‘foot-in-the-door’ for a bigger opportunity.”
              </p>
              <img
                src={Paul_Booij}
                className="rounded mx-auto d-block"
                style={{ height: 100 }}
                alt="hi"
              ></img>

              <ul className="aboutus">
                <li className="nam">Paul Booij</li>
                <li className="nam">Simulation Expert</li>
                <li className="nam">Founder & Managing Director,Optimulate</li>
              </ul>
            </div>
          </div>

          <div className="col-md-6">
            <div className="says-bg">
              <p style={{ margin: "5%" }}>
                “Digital tools like BtrLyf are disrupting the green building
                industry and putting all professionals on their toes to work
                smarter and adopt digitalization to succeed or otherwise
                struggle much harder to remain relevant in today’s day and age.”
              </p>
              <img
                src={TanTC}
                className="rounded mx-auto d-block"
                style={{ height: 120 }}
                alt="hi"
              ></img>
              <ul className="aboutus">
                <li className="nam">Paul Booij</li>
                <li className="nam">Simulation Expert</li>
                <li className="nam">Founder & Managing Director,Optimulate</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="What-heading">
        Refe<span className="underline">rences</span>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="says-bg">
              <div
                style={{
                  margin: "5px",
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <img
                  src={bank}
                  className="rounded mx-auto d-block"
                  style={{ height: 100, width: 90 }}
                  alt="hi"
                ></img>
                <div>
                  <ul
                    style={{
                      listStyle: "none",
                      color: "white",
                      fontSize: "12px",
                    }}
                  >
                    <li> Client: Large Private Bank</li>
                    <li>No. of Buildings: 15</li>
                    <li>Building Type: Mixed-use</li>
                    <li>Location: Singapore</li>
                    <li>Assessed GFA: 111,000 Sq.m.</li>
                    <li>Portfolio Energy Savings: 28% ($1.2 mil./yr)</li>
                    <li>Max Saving in a Building: 58%</li>
                  </ul>
                </div>
              </div>
              <p className="referances">
                Banking Portfolio
                <br />
                <span style={{ fontWeight: 400 }}>Singapore</span>
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="says-bg">
              <div
                style={{
                  margin: "5px",
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <img
                  src={malls}
                  className="rounded mx-auto d-block"
                  style={{ height: 100, width: 90 }}
                  alt="hi"
                ></img>
                <div>
                  <ul
                    style={{
                      listStyle: "none",
                      color: "white",
                      fontSize: "12px",
                    }}
                  >
                    <li> Client: Shopping Mall Operator</li>
                    <li> No. of Buildings: 6</li>
                    <li> Building Type: Retail</li>
                    <li> Location: Singapore</li>
                    <li> Assessed GFA: 310,000 Sq.m.</li>
                    <li> Portfolio Energy Savings: 32% ($4.7 mil./yr)</li>
                    <li> Max Saving in a Building: 43%</li>
                  </ul>
                </div>
              </div>
              <p className="referances">
                Retail Portfolio
                <br />
                <span style={{ fontWeight: 400 }}>Singapore</span>
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="says-bg">
              <div
                style={{
                  margin: "5px",
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <img
                  src={Servers}
                  className="rounded mx-auto d-block"
                  style={{ height: 100, width: 90 }}
                  alt="hi"
                ></img>
                <div>
                  <ul
                    style={{
                      listStyle: "none",
                      color: "white",
                      fontSize: "12px",
                    }}
                  >
                    <li> Client: Telecom provider</li>
                    <li> No. of Buildings: 2</li>
                    <li> Building Type: Data Centres</li>
                    <li> Location: Singapore</li>
                    <li> Assessed GFA: 86,000 Sq.m.</li>
                    <li> Portfolio Energy Savings: 14%</li>
                    <li> Max Saving in a Building: 20%</li>
                  </ul>
                </div>
              </div>
              <p className="referances">Data Centers</p>
            </div>
          </div>
        </div>
      </div>

      <div className="news-bg">
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div div className="What-heading">
          Featured News & U<span className="underline">pdates</span>
        </div>
        <br></br>
        <br></br>
        <div className="container">
          <div className="row">
            <div className="col-md-4 d-flex justify-content-center">
              <div className="news-blog">
                <Link to="new-blog">
                  <img
                    src={btrlyf_blog}
                    className="rounded mx-auto d-block"
                    style={{ width: "100%" }}
                    alt="hi"
                  ></img>
                  <div className="news-box">
                    <div>
                      <p className="news-text">
                        BtrLyf launches new Benchmarking Calculator
                      </p>
                    </div>
                    <div>
                      <p className="news-date">Jun 23, 2022</p>
                      <AiOutlineHeart className="news-icon" />
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-md-4 d-flex justify-content-center">
              <div className="news-blog">
                <Link to="new-blog">
                  <img
                    src={btrlyf_blog}
                    className="rounded mx-auto d-block"
                    style={{ width: "100%" }}
                    alt="hi"
                  ></img>
                  <div className="news-box">
                    <div>
                      <p className="news-text">
                        BtrLyf secures support under the Advanced Digital
                        Solutions funding from IMDA Singapore
                      </p>
                    </div>
                    <div>
                      <p className="news-date">Jun 23, 2022</p>
                      <AiOutlineHeart className="news-icon" />
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-md-4 d-flex justify-content-center">
              <div className="news-blog">
                <Link to="new-blog">
                  <img
                    src={btrlyf_blog}
                    className="rounded mx-auto d-block"
                    style={{ width: "100%" }}
                    alt="hi"
                  ></img>
                  <div className="news-box">
                    <div>
                      <p className="news-text">
                        COP26 and digital green assessment of buildings
                      </p>
                    </div>
                    <div>
                      <p className="news-date">Jun 23, 2022</p>
                      <AiOutlineHeart className="news-icon" />
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <NewContact />
      </div>
    </>
  );
}
