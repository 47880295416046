import React, { useEffect } from "react";
import "./style.css";
import grace from "../../assets/edgarscreen.png";
import graceabout from "../../assets/mackbook.png";
import feature1 from "../../assets/feature1.png";
import feature2 from "../../assets/feature2.png";
import feature3 from "../../assets/feature3.png";
import feature4 from "../../assets/feature4.png";
import feature5 from "../../assets/feature5.png";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import NewContact from "../../Components/NewContactForm/NewContact";
import { BiCheckCircle } from "react-icons/bi";

export default function NewEdgar() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section id="banner">
        <div className="grace-main">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-sm-12">
                <h3 className="grace-first-head">
                  BtrLyf <b>EDGAR</b>
                </h3>
                <h2 className="grace-second-head">
                  Enhanced Digital Green Assessor
                </h2>
                <p className="grace-para">
                  EDGAR increases productivity of a green assessment by 10-50x.
                  it allows for Do-it-Yourself (DIY) technical analysis in green
                  projects without the need for deep technical skills.
                </p>
                <a href="https://dev-edgar.selbtrlyf.com/">
                  <button className="grace-button">Open EDGAR</button>
                </a>
              </div>
              <div className="col-md-4 col-sm-12">
                <img src={grace} className="img-fluid" alt="Grace banner" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about-grace">
        <div className="container">
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6">
              <h3 className="about-us-head">About EDGAR</h3>
            </div>
            <div className="col-md-4 col-sm-12">
              <img src={graceabout} alt="grace about" className="img-fluid" />
            </div>
            <div className="col-md-8 col-sm-12">
              <p className="grace-about-para">
                {" "}
                <b>EDGAR (Enhanced Digital Green Assessor)</b>, increases
                productivity of a green assessment by 10-50x. it allows for
                Do-it-Yourself (DIY) technical analysis in green projects
                without the need for deep technical skills. It also prevents
                duplication of efforts that are common in the industry due to
                lack of an effective technical data sharing structure.
              </p>
              <p className="grace-about-para">
                EDGAR is best suited for
                <b>
                  building owners/facility managers, sustainability managers and
                  Environmental Sustainable Design (ESD) consultants,
                </b>{" "}
                who would like to do pre-qualification and initial assessment of
                buildings. It includes benchmarking, baseline report generation,
                auto and manual simulation of a wide range of Energy
                Conservation Measures (ECMs). One EDGAR license allows to do
                multiple building simulations and at multiple times as and when
                required. See full list of features below.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="features">
        <div className="grace-features">
          <div className="container-fuid">
            <div className="container">
              <h3 className="features-head">Features</h3>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6 feature-card-container">
                <div className="feature-card">
                  <img
                    src={feature1}
                    alt="GRACE feature"
                    className="img-fluid"
                  />
                  <p className="feature-text">
                    Open Access Digital Twins for Sustainability Assessment
                  </p>
                  <a href="#" className="feature-anchor">
                    Know More <IoIosArrowDropdownCircle />
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 feature-card-container">
                <div className="feature-card">
                  <img
                    src={feature2}
                    alt="GRACE feature"
                    className="img-fluid"
                  />
                  <p className="feature-text">
                    Search, Compare & Benchmark Building Performance
                  </p>
                  <a href="#" className="feature-anchor">
                    Know More <IoIosArrowDropdownCircle />
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 feature-card-container">
                <div className="feature-card">
                  <img
                    src={feature3}
                    alt="GRACE feature"
                    className="img-fluid"
                  />
                  <p className="feature-text">Add Data to Buildings</p>
                  <a href="#" className="feature-anchor">
                    Know More <IoIosArrowDropdownCircle />
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 feature-card-container">
                <div className="feature-card">
                  <img
                    src={feature4}
                    alt="GRACE feature"
                    className="img-fluid"
                  />
                  <p className="feature-text">
                    Open Access Digital Twins for Sustainability Assessment
                  </p>
                  <a href="#" className="feature-anchor">
                    Know More <IoIosArrowDropdownCircle />
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 feature-card-container">
                <div className="feature-card">
                  <img
                    src={feature5}
                    alt="GRACE feature"
                    className="img-fluid"
                  />
                  <p className="feature-text">
                    Auto & Manual Performance Simulation
                  </p>
                  <a href="#" className="feature-anchor">
                    Know More <IoIosArrowDropdownCircle />
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 feature-card-container">
                <div className="feature-card">
                  <img
                    src={feature5}
                    alt="GRACE feature"
                    className="img-fluid"
                  />
                  <p className="feature-text">
                    User Management & Access Control
                  </p>
                  <a href="#" className="feature-anchor">
                    Know More <IoIosArrowDropdownCircle />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="edgar-help">
        <div className="edgar-help">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <div className="edgar-help-header">
                  <h4>BtrLyf EDGAR</h4>
                  <h3>Will help you to</h3>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="help-item">
                      <p>
                        <span className="help-icon">
                          <BiCheckCircle />
                        </span>
                        <b>Understand</b> detailed{" "}
                        <b>energy consumption breakdown</b>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="help-item">
                      <p>
                        <span className="help-icon">
                          <BiCheckCircle />
                        </span>
                        <b>Suggest</b> Energy Conservation Measures (ECMs) to
                        achieve the desired <b>energy savings</b>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="help-item">
                      <p>
                        <span className="help-icon">
                          <BiCheckCircle />
                        </span>
                        Do a <b>cost-benefit analysis</b> of energy savings
                        measures (Capex, ROI)
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="help-item">
                      <p>
                        <span className="help-icon">
                          <BiCheckCircle />
                        </span>
                        Select ECMs on your own to achieve{" "}
                        <b>customized results</b>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="help-item">
                      <p>
                        <span className="help-icon">
                          <BiCheckCircle />
                        </span>
                        <b>Connect</b> to technology and{" "}
                        <b>solution providers</b> to implement and{" "}
                        <b>improve</b> energy <b>performance.</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="footer">
        <div className="grace-footer">
          <NewContact />
        </div>
      </section>
    </>
  );
}
