
export default {
wixsite_URL : 'https://www.btrlyf.com/',
youtube_UCWpPA_URL : 'https://youtube.com/channel/UCWpPA_dB05f6uvDbkq3BauQ/',
facebook_URL : 'https://facebook.com/thebtrlyf',
twitter_URL : 'https://twitter.com/btrlyf_built',
instragram_URL : 'https://instagram.com/accounts/login/',
linkedin_URL : 'https://linkedin.com/authwall?trk=ripf&trkInfo=AQEr0rnBIkifaAAAAX5W2-4oCZVqnUswo81Ld-NfbhHPf7Gud4mlXAbDUwBdQQ-AmRG5_PYd-eNsF-2VcOzprHx10QTWf18gFpdy_Fs3aMhUYSYsubJJZQBRb9mM9xAWX7OD-M8=&originalReferer=https://www.btrlyf.com/&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2F20406858%2F',
youtube_embed_URL : 'https://www.youtube.com/embed/ulL_QtbR-Oo',
hubspot_URL : 'https://js.hsforms.net/forms/shell.js',
youtube_URL : 'https://www.youtube.com/watch?v=KJPi7hyy28w',
btrlyf_blog_URL : 'https://list.btrlyf.com/blog',
btrlyf_URL : 'https://list.btrlyf.com/home',
btrlyf_feature_URL : 'https://list.btrlyf.com/features',
gracefeature_URL : 'https://grace.btrlyf.com/product-features',
gracr_URL : 'https://grace.btrlyf.com/',
edgar_URL : 'http://edgar.selbtrlyf.com/',

}
