import React from "react";

export default function Blog() {
  return (
    <>
      <div
        style={{
          height: "65vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h3>Dummy page for blog content</h3>
      </div>
    </>
  );
}
