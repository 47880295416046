import React from "react";
import { Col, Row } from "react-bootstrap";

import OurApps from "../Components/OurApps";
import { Map } from "../Components/Map";
import WhyBtrlyf from "../Components/WhyBtrlyf";
import Contact from "../Components/Contact";

import Testimonials from "../Components/Testimonials";
import edgar from "../assets/EDGAR.webp";
import config from '../config';
import advDigi from "../assets/advDigi.webp";
import qi from "../assets/qi.jpg";
import usebtrlyf from "../assets/usebtrlyf.webp";
import nilesh from "../assets/nilesh.jpg";
import ceo from "../assets/ceo.webp";
import Blogs from "../Components/Blogs";

const data = [
  {
    img1: advDigi,
    img2: qi,
    title: "Qi Square",
    subtitle:
      "BtrLyf secures support under the Advanced Digital Solutions funding from IMDA Singapore",
    desc: "The BtrLyf- Digital Built Environment Ecosystem by Qi Square is now supported and is a pre-approved solution under the Advanced Digital Solutions (ADS) funding provided by the Infocomm Media Development Authority (IMDA), Singapore.Announced as part of the Resilience Budget 2020 by Singapore’s Deputy Prime Minister (DPM) and Minister for Finance Heng Swee Keat, the ADS grant funding is to help enterprises deepen their digital capabilities, strengthen business continuity measures, and build longer term resilience. See more information about ADS on IMDA’ s website and refer to the ‘Sustainability’ project category for information on the BtrLyf digital package.",
  },
  {
    img1: usebtrlyf,
    img2: nilesh,
    title: "Nilesh Y. Jadhav",
    subtitle:
      "BtrLyf secures support under the Advanced Digital Solutions funding from IMDA Singapore",
    desc: "At COP26, experts are urging commercial real estate to be more sustainable to reach crucial climate-action goals. The largest challenge for the industry is retrofitting buildings to be more sustainable. Buildings can save 20% to 60% in operating costs through proven green retrofit measures with an excellent return on investment. However, building industry players do not have the tools to easily identify and quantify energy-saving opportunities, evaluate the latest technological solutions and access financing options. They end up spending a lot of time in the administration of green projects and finding the right partners to work with.",
  },
  {
    img1: ceo,
    img2: qi,
    title: "QiSquare",
    subtitle:
      "Qi Square secures strategic investment from SoftTech Engineers Ltd.",
    desc: "SoftTech Engineers Ltd (NSEI: SOFTTECH), announced its strategic investment in Singapore-based start-up Qi Square Pte Ltd. Softtech Engineers is a leading IT company with 25 years of expertise facilitating business & technology transformation across the Architectural Engineering Construction (AEC) industry. Equipped with 25 years of deep domain expertise and industry knowledge, SoftTech has helped more than 4,500 clients in India and around the world to gain a competitive digital edge in the industry.",
  },
];

function Home() {
  const handleEdgarKnowmore = () => {
    window.alert("feature is coming soon");
  };
  const handleEdgar = () => {
    //let Events = `${process.env.REACT_APP_Edgar}`;
    //window.open(Events);
    window.open(
      config.edgar_URL
    );
  };
  // const handleGrace = () => {
  //   //let Events = `${process.env.REACT_APP_Grace}`;
  //   //window.open(Events);
  //   window.open(
  //     "http://sbl-grace-site.s3-website-ap-southeast-1.amazonaws.com/"
  //   );
  // };
  // const handleLisa = () => {
  //   //let Events = `${process.env.REACT_APP_Lisa}`;
  //   //window.open(Events);
  //  window.open(config.btrlyf_URL);
  // };
  // const handlePria = () => {
  //   //let Events = `${process.env.REACT_APP_Pria}`;
  //   //window.open(Events);
  //   return <Link smooth to="#ContactUs"></Link>;
  // };

  // const handleLisaKnowmore = () => {
  //   window.alert("feature is coming soon");
  // };
  // const handlePriaKnowmore = () => {
  //   window.alert("feature is coming soon");
  // };
  // const handleGraceKnowmore = () => {
  //   window.alert("feature is coming soon");
  // };

  const row1 = [
    {
      key: 0,
      title: "EDGAR",
      img: edgar,
      subtitle: "Enhanced Digital Green Assessor",
      desc: "EDGAR increases productivity of a green assessment by 10-50x it allows for Do-it-Yourself (DIY) technical analysis in green projects without the need for deep technical skills.It also prevents duplication of efforts that are common in the industry due to lack of an effective technical data sharing structure.",
      know: handleEdgarKnowmore,
      dive: handleEdgar,
      color: "#6089cd",
      btnName: "Dive in",
      link: "/edgar",
    },
    // {
    //   key: 1,
    //   title: "PRIA",
    //   subtitle: "Product Inclusion in Assessment",
    //   img: pria,
    //   desc: "PRIA helps product suppliers, resellers and vendors to include their products for digital assessment. It allows for quantification and qualification of solutions to a higher degree of granularity and AI-enabled matching. Very effective digital tool for honing the selection process for products in green projects.Click on the know more button for more details.                                                         ",
    //   know: handlePriaKnowmore,
    //   dive: handlePria,
    //   color: "#cd007f",
    //   btnName: "Contact",
    //   link: "/pria",
    // },
  ];
  // const row2 = [
  //   {
  //     key: 2,
  //     title: "LISA",
  //     subtitle: "Listing of Industry Specialist App",
  //     img: lisa,
  //     desc: "Specially curated listing and project/product showcases for sustainable built environment. You can find and connect tothousands of green building industry specialists by their area and geography of work.",
  //     know: handleLisaKnowmore,
  //     dive: handleLisa,
  //     color: "blue",
  //     btnName: "Dive In",
  //   },
  //   {
  //     key: 3,
  //     title: "GRACE",
  //     subtitle: "Green Auto Certification tool",
  //     img: grace,
  //     desc: " Streamline green building certification data and get automation to work for you. GRACE will significantly enhance the productivity of green building certification projects.",
  //     know: handleGraceKnowmore,
  //     dive: handleGrace,
  //     color: "green",
  //     btnName: "Try Beta",
  //   },
  // ];

  return (
    <>
      <Map />
      <br />
      <Row id="Edgar">
        <Col>
          <h1 className="text-white text-center bg-dark">Our Digital Apps</h1>
        </Col>
      </Row>
      <br></br>
      <OurApps obj={row1} />
      {/* <br />
      <OurApps obj={row2} /> */}
      <WhyBtrlyf />
      <br />
    <Testimonials />
      <br />
      <br />
      <Row id="WhyBtrlyf">
        <Col>
          <h1 className="text-white text-center bg-dark">
            Featured News and Updates
          </h1>
        </Col>
      </Row>
      {/* <Whatssnew data={data} /> */}
      {/* <Blogs/> */}
      {/* <WhatNew />
      <IndustryBuzz />
      <Faq />
      <Facs /> */}
      <Contact />
      
    </>
  );
}

export default Home;
